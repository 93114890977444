import { TournamentContext } from 'context/Create';
import { RoundRobinContext } from 'context/stage/RequestStageRR';
import { AuthContext } from 'context/User';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    SingleEliminationPost,
    SingleEliminationPut,
} from 'request/Tournament/SingleElimination';

export const SingleEliminationContext = createContext(null);
export const SingleEliminationErrorContext = createContext(null);

export const RequestStageSEProvider = ({ children }) => {
    const { tournament } = useContext(TournamentContext);
    const { authUser } = useContext(AuthContext);
    const { roundRobin } = useContext(RoundRobinContext);

    const [singleElimination, setSingleElimination] = useState({
        object: {
            id: '',
            maxEntries: 2,
            maxEntriesEnabled: false,
            maxQualifyingRoundPosition: 1,
        },
        stageFrom: {
            stageRrIds: [],
        },
        tournamentId: '',
        editing: true,
    });

    const [singleEliminationError, setSingleEliminationError] = useState(null);

    useEffect(() => {
        if (tournament.id !== '') {
            setSingleElimination({
                ...singleElimination,
                tournamentId: tournament.id,
            });
        }
    }, [tournament.id]);
    console.log('singleElimination', singleElimination);

    useEffect(() => {
        if (roundRobin.object.id !== '') {
            setSingleElimination({
                ...singleElimination,
                stageFrom: {
                    stageRrIds: [
                        {
                            id: roundRobin.object.id,
                        },
                    ],
                },
            });
        }
    }, [roundRobin.object.id]);

    useEffect(() => {
        if (
            singleElimination.tournamentId !== '' &&
            typeof tournament.id === 'string' &&
            singleElimination.editing === false
        ) {
            if (singleElimination.object.id === '') {
                if (singleElimination.stageFrom.stageRrIds.length > 0) {
                    SingleEliminationPost(
                        authUser,
                        singleElimination,
                        setSingleElimination,
                        setSingleEliminationError
                    );
                }
            } else {
                SingleEliminationPut(
                    authUser,
                    singleElimination,
                    setSingleElimination,
                    setSingleEliminationError
                );
            }
        }
    }, [
        singleElimination.object.maxEntries,
        singleElimination.object.maxEntriesEnabled,
        singleElimination.object.maxQualifyingRoundPosition,
        singleElimination.tournamentId,
        singleElimination.stageFrom,
        singleElimination.editing,
    ]);

    return (
        <SingleEliminationContext.Provider
            value={{
                singleElimination,
                setSingleElimination,
            }}
        >
            <SingleEliminationErrorContext.Provider
                value={{
                    singleEliminationError,
                    setSingleEliminationError,
                }}
            >
                {children}
            </SingleEliminationErrorContext.Provider>
        </SingleEliminationContext.Provider>
    );
};
