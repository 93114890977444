import 'firebase/firestore';

import GameComponent from 'componets/game/game';
import { DurationContext } from 'context/format/Duration';
import { ViewGameContext } from 'context/view/game';
import React, { useContext } from 'react';

export default function GameContext(props) {
    const { game, gameScore } = useContext(ViewGameContext);
    const { duration } = useContext(DurationContext);
    return (
        <GameComponent
            game={game}
            gameScore={gameScore}
            start={duration.start}
            totalGames={props.totalGames}
            gameWeek={props.gameWeek}
            totalQualifiers={props.totalQualifiers}
        ></GameComponent>
    );
}

// add click functions maybe ask monz where.
