import { Box } from '@material-ui/core';
import BackdropItem from 'componets/backdrop/item/item';
import CreateDialog from 'componets/create/createDialog';
import RequeststageRR from 'containers/create/RoundRobin';
import {
    stageGroupEnabled,
    useError,
    useStageGroup,
    useStageGroupSeriesFormat,
} from 'context/create/Provider';
import { Table } from 'mdi-material-ui';
import React, { useState } from 'react';

export const GroupStage = () => {
    const [open, setOpen] = useState(false);
    const error = useError();
    const stage = useStageGroup();
    const formatSeries = useStageGroupSeriesFormat();
    const enabled = stageGroupEnabled();

    const onSave = () => {
        setOpen(false);
    };

    const disabled = false;
    const name = enabled
        ? 'Managers: ' +
          stage.object.maxEntriesPerGroup +
          ', Qualifiers: ' +
          stage.object.maxQualifiersPerGroup +
          ', ' +
          stage.object.minNumberOfRepetitions +
          '∞, BO' +
          formatSeries.object.gamesMax
        : 'No Group Stage';
    return (
        <Box>
            <BackdropItem
                icon={<Table />}
                placeholder={'Configure Group Stage'}
                placeholderActive={false}
                value={name}
                disabled={disabled}
                onClick={() => setOpen(true)}
                error={error}
            ></BackdropItem>
            <CreateDialog open={open} onClose={() => setOpen(false)}>
                <RequeststageRR handleSave={onSave} />
            </CreateDialog>
        </Box>
    );
};

export default GroupStage;
