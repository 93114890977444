import { Card, CardActions, CardContent } from '@material-ui/core';
import Header from 'componets/header/header';
import NumberSelect from 'componets/NumberSelect/NumberSelect';
import {
    ACTIONS,
    useCreate,
    useDuration,
    useError,
} from 'context/create/Provider';
import SaveButton from 'layout/SaveButton/SaveButton';
import React from 'react';

export default function DurationView({ handleSave, noSave }) {
    const { dispatch } = useCreate();

    const error = useError();
    const duration = useDuration();

    const handleChangeStartWeek = (event) => {
        dispatch({
            type: ACTIONS.SET_DURATION_START,
            payload: event.target.value,
        });
    };
    const handleEdit = () => {};

    const startWeekDisabled = () => {
        return false;
    };

    return (
        <Card>
            <Header title="Duration" error={error} onClick={handleEdit} />

            <CardContent>
                <NumberSelect
                    inputLabel={'Starting GW Week'}
                    formHelperText={
                        'For all tournaments starting in future gameweeks, please note that that all Manager and group stage configurations are applied at the time of the tournament being generated. If you want the most up to date information, create the tournament '
                    }
                    min={1}
                    max={38}
                    value={duration.start}
                    disabledFunc={startWeekDisabled}
                    handleChange={handleChangeStartWeek}
                />
            </CardContent>
            <CardActions>
                <SaveButton onClick={handleSave} noSave={noSave} />
            </CardActions>
        </Card>
    );
}
