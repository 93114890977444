import 'firebase/firestore';

import { CardActionArea, Divider, Grid, Typography } from '@material-ui/core';
import ViewLayoutName from 'layout/view/name';
import React from 'react';

export default function GameCardActionTwo(props) {
    return (
        <CardActionArea key={props.entryId} onClick={props.click}>
            <Divider />
            <Grid
                container
                justifyContent="space-between"
                style={
                    (props.index < props.totalQualifiers && {
                        // eslint-disable-next-line no-undef
                        borderLeft: '3px solid ' + colorSelector(props.index),
                        paddingLeft: '1px',
                    }) || {
                        borderLeft: '3px solid transparent',
                        paddingLeft: '1px',
                    }
                }
            >
                <Grid item xs={1}>
                    <Typography variant="body2" align="center">
                        {props.index + 1}
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography
                        variant="body2"
                        style={{ paddingLeft: '2%' }}
                        noWrap
                    >
                        <ViewLayoutName
                            entry_id={props.entryId}
                        ></ViewLayoutName>
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" align="center">
                        {props.points}
                    </Typography>
                </Grid>
            </Grid>
        </CardActionArea>
    );
}
const ChampionsLeagueColor = '#0E1E5B';
const EuropaLeagueColor = '#f39200';
const UEL2LeagueColor = '#008000';

const colorSelector = (index) => {
    switch (index) {
        case 0:
            // code block
            return ChampionsLeagueColor;
        case 1:
            // code block
            return EuropaLeagueColor;
        case 2:
            // code block
            return UEL2LeagueColor;
        default:
            return ChampionsLeagueColor;
        // code block
    }
};
