import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { QueryResponseContext } from 'context/query/response';
import { ViewRoundProvider } from 'context/view/round';
import ViewLayoutRound from 'layout/view/round/round';
import React, { useContext, useEffect, useState } from 'react';

export default function ViewLayoutRoundList(props) {
    const { startWeek } = props;
    const { queryResponse } = useContext(QueryResponseContext);
    const [gameWeek, setGameWeek] = useState(startWeek);

    useEffect(() => {
        let lastActiveRoundIndex = 0;
        for (let i = 0; i < queryResponse.list.length; i++) {
            lastActiveRoundIndex = i;
            if (
                queryResponse.list[i].base.status.value === 'ACTIVE' ||
                queryResponse.list[i].base.status.value === 'WAITING'
            ) {
                break;
            }
        }
        setGameWeek(startWeek + lastActiveRoundIndex);
    }, [startWeek, queryResponse.list]);

    return (
        <Paper elevation={1}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setGameWeek(gameWeek - 1)}
                        disabled={gameWeek <= startWeek}
                    >
                        {' '}
                        <Typography variant="caption">Prev</Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Typography
                        variant="body2"
                        style={{ marginTop: '5px' }}
                        justifyContent="center"
                        align="center"
                    >
                        Round {gameWeek}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setGameWeek(gameWeek + 1)}
                        disabled={
                            gameWeek >=
                            startWeek + queryResponse.list.length - 1
                        }
                    >
                        <Typography variant="caption">Next</Typography>
                    </Button>
                </Grid>
            </Grid>
            <TabPanel>
                {queryResponse.list.map((item, index) => (
                    <ViewRoundProvider id={item.object.id} key={item.object.id}>
                        <ViewLayoutRound
                            visible={gameWeek - startWeek === index}
                            gameWeek={startWeek + index}
                        ></ViewLayoutRound>
                    </ViewRoundProvider>
                ))}
            </TabPanel>
        </Paper>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}
