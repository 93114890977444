import Box from '@material-ui/core/Box';
import { QueryResponseContext } from 'context/query/response';
import { ViewSeriesProvider } from 'context/view/series';
import ViewLayoutSeries from 'layout/view/series/series';
import React, { useContext } from 'react';

export default function ViewLayoutSeriesList(props) {
    const { queryResponse } = useContext(QueryResponseContext);

    return (
        <Box>
            {queryResponse.list.map((item) => (
                <ViewSeriesProvider id={item.object.id} key={item.object.id}>
                    <ViewLayoutSeries
                        gameWeek={props.gameWeek}
                        totalQualifiers={props.totalQualifiers}
                    ></ViewLayoutSeries>
                </ViewSeriesProvider>
            ))}
        </Box>
    );
}
