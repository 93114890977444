import { ButtonBase, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

export const BackdropItem = ({
    onClick,
    placeholder,
    placeholderActive,
    value,
    icon,
    disabled,
}) => {
    const theme = useTheme();
    return (
        <ButtonBase
            variant="contained"
            disabled={disabled}
            style={{
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.light,
                width: 300,
                borderRadius: 4,
                height: 30,
                padding: 4,
            }}
            onClick={onClick}
        >
            <Grid
                container
                justifyContent="flex-start"
                alignContent="center"
                spacing={1}
            >
                <Grid
                    item
                    style={{
                        color:
                            placeholderActive && !disabled
                                ? theme.palette.secondary.main
                                : theme.palette.primary.contrastText,
                        opacity: disabled ? 0.5 : 1,
                    }}
                >
                    {icon}
                </Grid>
                <Grid item>
                    {placeholderActive ? (
                        <Typography
                            variant="body2"
                            style={{ opacity: 0.5, marginTop: 2 }}
                        >
                            {placeholder}
                        </Typography>
                    ) : (
                        <Typography variant="body2" style={{ marginTop: 2 }}>
                            {value}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </ButtonBase>
    );
};

export default BackdropItem;
