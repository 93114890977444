import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import FindIcon from '@material-ui/icons/FindInPage';
import HomeIcon from '@material-ui/icons/Home';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import EmptyState from '../../layout/EmptyState/EmptyState';

const styles = (theme) => ({
    emptyStateIcon: {
        fontSize: theme.spacing(12),
    },

    button: {
        marginTop: theme.spacing(1),
    },

    buttonIcon: {
        marginRight: theme.spacing(1),
    },
});

class NotFoundContent extends Component {
    render() {
        // Styling
        const { classes } = this.props;

        return (
            <EmptyState
                icon={
                    <FindIcon
                        className={classes.emptyStateIcon}
                        color="action"
                    />
                }
                title="Incorrecto URL!"
                description="404, this page does not exist."
                button={
                    <Fab
                        className={classes.button}
                        color="secondary"
                        component={Link}
                        to="/"
                        variant="extended"
                    >
                        <HomeIcon className={classes.buttonIcon} /> Go Home
                    </Fab>
                }
            />
        );
    }
}

NotFoundContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFoundContent);
