import { Hidden } from '@material-ui/core';
import logo from 'assets/logo-1';
import logoLong from 'assets/logo-long.png';
import React from 'react';
import { Link } from 'react-router-dom';

export const Logo = () => {
    return (
        <Link
            to="/"
            style={{
                textDecoration: 'none',
                color: 'inherit',
            }}
        >
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <img src={logo} alt="Logo" height={28} />
            </Hidden>
            <Hidden only="xs">
                <img src={logoLong} alt="Logo" height={28} />
            </Hidden>
        </Link>
    );
};

export default Logo;
