import 'firebase/firestore';

import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import GameCardActionOne from './gameCardActionOne';
import GameCardActionTwo from './gameCardActionTwo';
import GameGridOne from './gameGridOne';

/* PROPs needed
id
entryList
click function
table
clickTwo function */
const urlPostfixHistory = 'history';

const urlPrefix = 'https://fantasy.premierleague.com/entry';
const urlPostfixGameweek = 'event';

export default function GameGridTwo(props) {
    return (
        <Grid container justifyContent="space-between">
            {(props.id !== '' && <GameGridOne></GameGridOne>) ||
                (props.entryList.length !== 0 &&
                    props.entryList.map((item) => (
                        <GameCardActionOne
                            key={item.id}
                            click={() =>
                                window.open(
                                    `${urlPrefix}/${item.id}/${urlPostfixHistory}`,
                                    '_blank'
                                )
                            }
                            id={item.id}
                        ></GameCardActionOne>
                    ))) || (
                    <Typography variant="caption" noWrap>
                        No Managers Qualified
                    </Typography>
                )}
            {props.table.map((item, index) => (
                <GameCardActionTwo
                    key={item.entryId}
                    click={() =>
                        window.open(
                            `${urlPrefix}/${item.entryId}/${urlPostfixGameweek}/${props.gameWeek}`,
                            '_blank'
                        )
                    }
                    entryId={item.entryId}
                    index={index}
                    points={item.values.points}
                    totalQualifiers={props.totalQualifiers}
                ></GameCardActionTwo>
            ))}
        </Grid>
    );
}
