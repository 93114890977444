import { Box } from '@material-ui/core';
import BackdropItem from 'componets/backdrop/item/item';
import CreateDialog from 'componets/create/createDialog';
import Grouping from 'containers/create/Grouping';
import { useError, useGrouping, useTournament } from 'context/create/Provider';
import { AccountGroup } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';

export const GroupingSelect = () => {
    const error = useError();
    const grouping = useGrouping();
    const tournament = useTournament();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (grouping) {
            setOpen(false);
        }
    }, [grouping]);

    const disabled = tournament ? tournament.id !== '' : false;
    const name = grouping ? grouping.name : '';
    return (
        <Box>
            <BackdropItem
                icon={<AccountGroup />}
                placeholder={'Select a Mini league'}
                placeholderActive={grouping === null}
                value={name}
                disabled={disabled}
                onClick={() => setOpen(true)}
                error={error}
            ></BackdropItem>
            <CreateDialog open={open} onClose={() => setOpen(false)}>
                <Grouping />
            </CreateDialog>
        </Box>
    );
};

export default GroupingSelect;
