import { ViewTournamentContext } from 'context/view/tournament';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { SingleEliminationGetNoAuth } from 'request/Tournament/SingleElimination';

export const ViewSEContext = createContext(null);

export const ViewSEProvider = (props) => {
    const { children } = props;
    const { tournament } = useContext(ViewTournamentContext);
    const [se, setSE] = useState({
        object: {
            id: props.id,
            maxEntriesPerGroup: 0,
            maxQualifiersPerGroup: 0,
            minNumberOfRepetitions: 0,
        },
        base: {
            entryList: [],
            scoreList: [],
            status: {
                value: '',
            },
            sequence: [
                {
                    stage: 0,
                },
            ],
        },
    });
    const setError = (error) => {
        console.log(error);
    };
    useEffect(() => {
        if (se.object.id !== '') {
            SingleEliminationGetNoAuth(
                tournament.object.id,
                se.object.id,
                setSE,
                setError
            );
        }
    }, [se.object.id]);

    return (
        <ViewSEContext.Provider
            value={{
                se,
                setSE,
            }}
        >
            {children}
        </ViewSEContext.Provider>
    );
};
