import axios from 'axios';

const url = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE + '/user';

export const update = (authUser, request, setError) => {
    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'put',
            url: url,
            data: request,
            headers: { Authorization: 'Bearer ' + idToken },
        };
        axios(opts)
            .then(function () {
                setError(null);
            })
            .catch(function (error) {
                setError(error.response);
            });
    });
};

export const get = (authUser, setDBUser, setError) => {
    return authUser
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
            const opts = {
                method: 'get',
                url: url,
                headers: { Authorization: 'Bearer ' + idToken },
            };
            axios(opts)
                .then(function (response) {
                    setDBUser(response.data);
                    setError(null);
                })
                .catch(function (error) {
                    setError(error.response);
                });
        });
};
