import {
    Card,
    IconButton,
    List,
    ListItemSecondaryAction,
    Typography,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
// Firebase
import app from 'App/Base.js';
import Header from 'componets/header/header';
import { GroupingContext } from 'context/Create';
import { ACTIONS, useCreate } from 'context/create/Provider';
import { EntryContext, UserContext } from 'context/User';
import React, { useContext, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 800,
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },
}));

export default function SimpleList() {
    const { entryUser } = useContext(EntryContext);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                <Header
                    title="Leagues"
                    subheader={'Imported from ' + entryUser.name}
                ></Header>
                <List
                    component="nav"
                    aria-label="imported fantasy premier leagues"
                >
                    <GroupingsEntry></GroupingsEntry>
                </List>
                <Divider />
            </Card>
        </div>
    );
}

const db = app.firestore();

const GroupingsEntry = () => {
    const { dbUser } = useContext(UserContext);
    const [groupingsMap, setGroupingsMap] = React.useState(new Map());
    const { grouping, setGrouping } = useContext(GroupingContext);

    const groupingsEntry = [];

    // when the saved entry id changes, update the entry object
    useEffect(() => {
        console.log('grouping entry', dbUser.groupings_entry);
        if (dbUser.groupings_entry !== null) {
            console.log('there are groupings');
            for (let i = 0; i < dbUser.groupings_entry.length; i++) {
                const groupingID = dbUser.groupings_entry[i];
                db.collection('grouping')
                    .doc(groupingID)
                    .onSnapshot(function (doc) {
                        if (doc.exists) {
                            console.log('grouping:', groupingID, doc.data());
                            setGroupingsMap(
                                (prev) =>
                                    new Map([...prev, [groupingID, doc.data()]])
                            );
                        } else {
                            console.log('grouping does not exist:', doc.data());
                        }
                    });
            }
            const existingEntryLength = groupingsEntry.length;
            for (let i = 0; i < existingEntryLength; i++) {
                groupingsEntry.pop();
            }
        }
    }, [dbUser.groupings_entry, dbUser.id]);

    if (groupingsMap.size === 0) {
        return <div></div>;
    }

    const it = groupingsMap.values();
    let result = it.next();

    while (!result.done) {
        const groupingObject = result.value;
        let selected = false;
        if (grouping !== null) {
            selected = groupingObject.id === grouping.id;
        }
        if (!groupingObject.over_sized) {
            groupingsEntry.push(
                <GroupingItem
                    groupingObject={groupingObject}
                    selected={selected}
                    handleListItemClick={setGrouping}
                    key={groupingObject.id}
                />
            );
        }
        result = it.next();
    }
    return groupingsEntry;
};

const GroupingItem = ({ groupingObject, selected, handleListItemClick }) => {
    const { dispatch } = useCreate();

    const date = new Date();
    date.setTime(groupingObject.timestamp_update_complete.seconds * 1000);
    const timeString = timeSince(date);
    const handleClick = () => {
        handleListItemClick(groupingObject);
        dispatch({ type: ACTIONS.SET_GROUPING, payload: groupingObject });
    };
    const selectionDisabled = groupingObject.total_ids > 256;
    return (
        <ListItem
            component="nav"
            aria-label="main mailbox folders"
            selected={selected}
        >
            <ListItemText
                component={'span'}
                primary={groupingObject.name}
                secondary={
                    <React.Fragment>
                        <Typography key="managers">
                            Managers: {groupingObject.total_ids.toString()}
                        </Typography>
                        {selectionDisabled ? (
                            <Typography key="updated" variant="caption">
                                Please{' '}
                                <a href={'mailto:' + 'team@fplplugin.com'}>
                                    contact us
                                </a>{' '}
                                if you wish to create a tournament with a large
                                amount of managers.
                            </Typography>
                        ) : (
                            <Typography key="updated">
                                Updated: {timeString} ago
                            </Typography>
                        )}
                    </React.Fragment>
                }
            />
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => handleClick()}
                    color="primary"
                    disabled={selectionDisabled}
                >
                    <SendIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const timeSince = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
};
