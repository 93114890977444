import {
    Avatar,
    Card,
    Container,
    IconButton,
    ListItemSecondaryAction,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import app from 'App/Base.js';
import { AuthContext, UserContext } from 'context/User';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { BookmarkDelete } from 'request/User/Bookmark';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function NestedList() {
    const { dbUser } = useContext(UserContext);

    const classes = useStyles();
    return (
        <Container>
            <Card>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                        >
                            Bookmarked Tournaments
                        </ListSubheader>
                    }
                    className={classes.root}
                >
                    {dbUser.tournaments_bookmarked !== null &&
                        dbUser.tournaments_bookmarked.map((item) => (
                            <BookmarkItem id={item} key={item} />
                        ))}
                </List>
            </Card>
        </Container>
    );
}

const db = app.firestore();

const BookmarkItem = ({ id }) => {
    const { authUser } = useContext(AuthContext);

    const [tournament, setTournament] = useState({
        name: '',
    });
    const [open, setOpenView] = useState(null);
    const [bookmarked, setBookmarked] = useState(false);
    const [bookmarkedError, setBookmarkedError] = useState(null);

    useEffect(() => {
        db.collection('tournament')
            .doc(id)
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    console.log('tournament:', id, doc.data());
                    setTournament(doc.data());
                } else {
                    console.log('tournament does not exist:', doc.data());
                }
            });
    }, []);

    const setBookmarkedTrue = () => {
        setBookmarked(true);
    };

    const handleBookmark = () => {
        BookmarkDelete(authUser, id, setBookmarkedTrue, setBookmarkedError);
    };
    if (open === true) {
        return <Redirect to={'/tour/' + id} />;
    }

    const acronym = tournament.name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');

    return (
        <ListItem button onClick={() => setOpenView(true)}>
            <ListItemIcon>
                <Avatar>{acronym}</Avatar>
            </ListItemIcon>
            <ListItemText primary={tournament.name} />
            {bookmarkedError}
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleBookmark}
                >
                    {bookmarked}
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
