import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = {
    center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },
};

class EmptyState extends Component {
    render() {
        // Styling

        // Properties
        const { icon, title, subtitle, description, button } = this.props;

        return (
            <Grid
                container
                direction="column"
                spacing={1}
                alignItems="center"
                style={{ marginTop: 65 }}
            >
                <Grid item xs={12}>
                    {icon}
                </Grid>
                <Grid item xs={12}>
                    {title && (
                        <Typography
                            style={{ padding: 5 }}
                            color="textSecondary"
                            variant="h2"
                        >
                            {title}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {subtitle && (
                        <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            align="center"
                        >
                            <i>{subtitle}</i>
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {description && (
                        <Typography
                            color="textSecondary"
                            variant="overline"
                            align="center"
                        >
                            {description}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                        align="center"
                    >
                        <i>for Fantasy Premier League</i>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {button}
                </Grid>
            </Grid>
        );
    }
}

EmptyState.propTypes = {
    classes: PropTypes.object.isRequired,

    icon: PropTypes.element,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.element,
};

export default withStyles(styles)(EmptyState);
