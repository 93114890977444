import { Box } from '@material-ui/core';
import NumberSelect from 'componets/NumberSelect/NumberSelect';
import React from 'react';

export default function RequestFormatSeries({
    formatSeries,
    handleChangeGamesMax,
    gamesMaxDisabled,
    disabled,
    disabledText,
}) {
    const helperText = disabled
        ? disabledText
        : 'The total score over ' +
          formatSeries.object.gamesMax.toString() +
          ' game weeks';
    return (
        <Box>
            <NumberSelect
                inputLabel={'How many game weeks in a fixture?'}
                formHelperText={helperText}
                min={1}
                max={16}
                value={formatSeries.object.gamesMax}
                disabledFunc={gamesMaxDisabled}
                handleChange={handleChangeGamesMax}
                disabled={disabled}
            />
        </Box>
    );
}
