// Firebase
// React
// Firebase
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/firestore';

import { Hidden } from '@material-ui/core';
import app from 'App/Base.js';
import constraints from 'constraints';
import ConfirmationDialog from 'dialogs/ConfirmationDialog/ConfirmationDialog';
import SignInDialog from 'dialogs/SignInDialog/SignInDialog';
import SignUpDialog from 'dialogs/SignUpDialog/SignUpDialog';
import WelcomeDialog from 'dialogs/WelcomeDialog/WelcomeDialog';
import firebase from 'firebase/app';
import React, { createContext, Fragment, useEffect, useState } from 'react';
import readingTime from 'reading-time';
import settings from 'settings';
import validate from 'validate.js';

export const AuthenticatorContext = createContext(null);
const auth = app.auth();

export const AuthenticatorProvider = ({ children }) => {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [userObject, setUserObject] = useState({
        userObject: '',
        displayName: '',
        emailAddress: '',
        photoURL: '',
    });
    const [menu, setMenu] = useState(null);

    const [signUpDialogOpen, setSignUpDialogOpen] = useState(false);
    const [signInDialogOpen, setSignInDialogOpen] = useState(false);
    const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);
    const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(false);

    const [isPerformingAuthAction, setIsPerformingAuthAction] = useState(false);
    const [snackbar, setSnackbar] = useState({
        autoHideDuration: 0,
        message: '',
        open: false,
    });
    console.log(snackbar);
    const handleSignOutClick = () => {
        setMenu(null);
        setSignOutDialogOpen(true);
    };

    const handleSignInClick = () => {
        setSignInDialogOpen(true);
    };
    const openMenu = (event) => {
        const anchorEl = event.currentTarget;
        setMenu(anchorEl);
    };

    const signOut = () => {
        if (!isSignedIn) {
            return;
        }

        setIsPerformingAuthAction(true);
        auth.signOut()
            .then(() => {
                setSignInDialogOpen(false);
                setSignOutDialogOpen(false);

                openSnackbar('Signed out');
            })
            .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                    default:
                        openSnackbar(message);
                }
            })
            .finally(() => {
                setIsPerformingAuthAction(false);
            });
    };

    const signUp = (emailAddress, password, passwordConfirmation) => {
        if (isSignedIn) {
            return;
        }

        if (!emailAddress || !password || !passwordConfirmation) {
            return;
        }

        const errors = validate(
            {
                emailAddress: emailAddress,
                password: password,
                passwordConfirmation: passwordConfirmation,
            },
            {
                emailAddress: constraints.emailAddress,
                password: constraints.password,
                passwordConfirmation: constraints.passwordConfirmation,
            }
        );

        if (errors) {
            return;
        }

        setIsPerformingAuthAction(true);
        auth.createUserWithEmailAndPassword(emailAddress, password)
            .then(() => {
                setSignInDialogOpen(false);
                setWelcomeDialogOpen(true);
            })
            .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                    case 'auth/email-already-in-use':
                    case 'auth/invalid-email':
                    case 'auth/operation-not-allowed':
                    case 'auth/weak-password':
                        openSnackbar(message);
                        return;

                    default:
                        openSnackbar(message);
                }
            })
            .finally(() => {
                setIsPerformingAuthAction(false);
            });
    };

    const signIn = (emailAddress, password) => {
        if (isSignedIn) {
            return;
        }

        if (!emailAddress || !password) {
            return;
        }

        const errors = validate(
            {
                emailAddress: emailAddress,
                password: password,
            },
            {
                emailAddress: constraints.emailAddress,
                password: constraints.password,
            }
        );

        if (errors) {
            return;
        }

        setIsPerformingAuthAction(true);
        auth.signInWithEmailAndPassword(emailAddress, password)
            .then((value) => {
                setSignInDialogOpen(false);
                const user = value.user;
                const displayName = user.displayName;
                const emailAddress = user.email;
                setUserObject(user);

                openSnackbar(`Signed in as ${displayName || emailAddress}`);
            })
            .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                    case 'auth/invalid-email':
                    case 'auth/user-disabled':
                    case 'auth/user-not-found':
                    case 'auth/wrong-password':
                        openSnackbar(message);
                        return;

                    default:
                        openSnackbar(message);
                }
            })
            .finally(() => {
                setIsPerformingAuthAction(false);
            });
    };

    const signInWithProvider = (provider) => {
        if (isSignedIn) {
            return;
        }

        if (!provider) {
            return;
        }

        setIsPerformingAuthAction(true);
        auth.signInWithPopup(provider)
            .then((value) => {
                setSignUpDialogOpen(false);
                setSignInDialogOpen(false);
                const user = value.user;
                const displayName = user.displayName;
                const emailAddress = user.email;
                setUserObject(user);

                openSnackbar(`Signed in as ${displayName || emailAddress}`);
            })
            .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                    case 'auth/account-exists-with-different-credential':
                    case 'auth/auth-domain-config-required':
                    case 'auth/cancelled-popup-request':
                    case 'auth/operation-not-allowed':
                    case 'auth/operation-not-supported-in-this-environment':
                    case 'auth/popup-blocked':
                    case 'auth/popup-closed-by-user':
                    case 'auth/unauthorized-domain':
                        openSnackbar(message);
                        return;

                    default:
                        openSnackbar(message);
                }
            })
            .finally(() => {
                setIsPerformingAuthAction(false);
            });
    };

    const openSnackbar = (message) => {
        setSnackbar({
            autoHideDuration: readingTime(message).time * 2,
            message,
            open: true,
        });
    };

    // when the saved entry id changes, update the entry object
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setIsSignedIn(!!user);
            setUserObject(user);
        });
    }, []);

    return (
        <AuthenticatorContext.Provider
            value={{
                isSignedIn,
                userObject,
                isPerformingAuthAction,
                handleSignOutClick,
                handleSignInClick,
                openMenu,
                setMenu,
                menu,
            }}
        >
            {children}

            {isSignedIn && (
                <React.Fragment>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <WelcomeDialog
                            fullScreen
                            open={welcomeDialogOpen}
                            title={settings.title}
                            user={userObject}
                            isPerformingAuthAction={isPerformingAuthAction}
                            onClose={() => {
                                setWelcomeDialogOpen(false);
                            }}
                            onCancelClick={() => {
                                setWelcomeDialogOpen(false);
                            }}
                            onVerifyClick={() => {
                                setWelcomeDialogOpen(false);
                            }}
                        />
                    </Hidden>

                    <ConfirmationDialog
                        open={signOutDialogOpen}
                        title="Sign out?"
                        contentText="While signed out you are unable to manage your profile and conduct other activities that require you to be signed in."
                        okText="Sign Out"
                        disableOkButton={isPerformingAuthAction}
                        highlightOkButton
                        onClose={() => {
                            setSignOutDialogOpen(false);
                        }}
                        onCancelClick={() => {
                            setSignOutDialogOpen(false);
                        }}
                        onOkClick={() => {
                            signOut();
                        }}
                    />
                </React.Fragment>
            )}

            {!isSignedIn && (
                <Fragment>
                    <Hidden only="xs">
                        <SignUpDialog
                            open={signUpDialogOpen}
                            isPerformingAuthAction={isPerformingAuthAction}
                            signUp={signUp}
                            onClose={() => {
                                setSignUpDialogOpen(false);
                            }}
                            onAuthProviderClick={signInWithProvider}
                        />

                        <SignInDialog
                            open={signInDialogOpen}
                            isPerformingAuthAction={isPerformingAuthAction}
                            signIn={signIn}
                            onClose={() => {
                                setSignInDialogOpen(false);
                            }}
                            onAuthProviderClick={signInWithProvider}
                            onResetPasswordClick={false}
                        />
                    </Hidden>

                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <SignUpDialog
                            fullScreen
                            open={signUpDialogOpen}
                            isPerformingAuthAction={isPerformingAuthAction}
                            signUp={signUp}
                            onClose={() => {
                                setSignUpDialogOpen(false);
                            }}
                            onAuthProviderClick={signInWithProvider}
                        />

                        <SignInDialog
                            fullScreen
                            open={signInDialogOpen}
                            isPerformingAuthAction={isPerformingAuthAction}
                            signIn={signIn}
                            onClose={() => {
                                setSignInDialogOpen(false);
                            }}
                            onAuthProviderClick={signInWithProvider}
                            onResetPasswordClick={false}
                        />
                    </Hidden>
                </Fragment>
            )}
        </AuthenticatorContext.Provider>
    );
};
