import {
    Get,
    GetNoAuth,
    Post,
    PostUpdate,
    Put,
    Query,
} from 'request/Tournament/Base';
import { urlWithID, urlWithTournament } from 'request/Tournament/utl';

const postfix = 'group';

export const GroupQuery = (request, setResponse, setError) => {
    return Query(
        urlWithTournament(postfix, request.tournamentId),
        request,
        setResponse,
        setError
    );
};

export const GroupPost = (authUser, request, setResponse, setError) => {
    return Post(
        urlWithTournament(postfix, request.tournamentId),
        authUser,
        request,
        setResponse,
        setError
    );
};

export const GroupPut = (authUser, request, setResponse, setError) => {
    return Put(
        urlWithID(postfix, request.tournamentId, request.object.id),
        authUser,
        request,
        setResponse,
        setError
    );
};

export const GroupGet = (authUser, tournamentid, id, setResponse, setError) => {
    return Get(
        urlWithID(postfix, tournamentid, id),
        authUser,
        setResponse,
        setError
    );
};

export const GroupGetNoAuth = (tournamentid, id, setResponse, setError) => {
    return GetNoAuth(
        urlWithID(postfix, tournamentid, id),
        setResponse,
        setError
    );
};

export const GroupUpdate = (tournamentid, id, setResponse, setError) => {
    return PostUpdate(
        urlWithID(postfix, tournamentid, id),
        setResponse,
        setError
    );
};
