import { Box } from '@material-ui/core';
import BackdropItem from 'componets/backdrop/item/item';
import CreateDialog from 'componets/create/createDialog';
import TournamentName from 'containers/create/TournamentName';
import { useError, useTournament } from 'context/create/Provider';
import { RenameBox } from 'mdi-material-ui';
import React, { useState } from 'react';

export const TournamentNameItem = () => {
    const [open, setOpen] = useState(false);
    const tournament = useTournament();
    const error = useError();

    const onSave = () => {
        console.log('clicked tournament name');
        setOpen(false);
    };

    const disabled = false;
    return (
        <Box>
            <BackdropItem
                icon={<RenameBox />}
                placeholder="Name your tournament"
                placeholderActive={
                    tournament === null && typeof tournament.id === 'string'
                }
                value={tournament.name}
                disabled={disabled}
                onClick={() => setOpen(true)}
                error={error}
            ></BackdropItem>
            <CreateDialog open={open} onClose={() => setOpen(false)}>
                <TournamentName handleSave={onSave} />
            </CreateDialog>
        </Box>
    );
};

export default TournamentNameItem;
