import {
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import Status from 'componets/create/status';
import {
    ACTIONS,
    stageGroupEnabled,
    stageKnockoutEnabled,
    useCreate,
    useError,
    useGrouping,
    useTournament,
} from 'context/create/Provider';
import { AuthContext } from 'context/User';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import { TournamentPostInit } from 'request/Tournament/Tournament';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    actions: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        flexGrow: 1,
        width: '100%',
        height: '100%',
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function Generate() {
    const classes = useStyles();
    const tournament = useTournament();
    const error = useError();
    const grouping = useGrouping();
    // const stageGroup = useStageGroup();
    // const stageGroupSeriesFormat = useStageGroupSeriesFormat();
    // const stageKnockout = useStageKnockout();
    // const stageKnockoutSeriesFormat = useStageKnockoutSeriesFormat();
    const stageGroupEnabledValue = stageGroupEnabled();
    const stageKnockoutEnabledValue = stageKnockoutEnabled();

    const { authUser } = useContext(AuthContext);
    const { dispatch } = useCreate();

    let stageStatusName = 'Need at least one stage';
    if (stageKnockoutEnabledValue && stageGroupEnabledValue) {
        stageStatusName = 'Group Stage -> Knockout Stage';
    } else if (stageKnockoutEnabledValue) {
        stageStatusName = 'Knockout Stage Only';
    } else if (stageGroupEnabledValue) {
        stageStatusName = 'Group Stage Only';
    }

    // const [creating, setCreating] = useState(false);
    const [loading, setLoading] = useState(false);
    const setTournament = (value) => {
        dispatch({
            type: ACTIONS.SET_TOURNAMENT,
            payload: value,
        });
    };

    const setError = (error) => {
        dispatch({
            type: ACTIONS.SET_ERROR,
            payload: error,
        });
    };

    console.log('refresh');
    if (
        tournament.response !== null &&
        typeof tournament.response !== 'undefined'
    ) {
        if (tournament.response.base.status.value === 'ACTIVE') {
            const url = '/tour/' + tournament.id;
            return <Redirect to={url} />;
        }
    }

    if (tournament.base !== null && typeof tournament.base !== 'undefined') {
        if (tournament.base.status.value === 'ACTIVE') {
            const url = '/tour/' + tournament.object.id;
            return <Redirect to={url} />;
        }
    }

    // const handleChange = (event) => {
    //   setTournament({ ...tournament, name: event.target.value })
    // }

    const createAndView = () => {
        TournamentPostInit(
            authUser,
            tournament,
            setTournament,
            grouping,
            setError
        );
    };

    const handleButtonClick = () => {
        setLoading(true);
        createAndView();
    };
    const tournamentReady = tournament.name !== '';
    const groupingSelected = grouping.id !== '';
    const stagesValid = stageGroupEnabledValue || stageKnockoutEnabledValue;
    return (
        <Card>
            <CardActions>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                    spacing={2}
                >
                    <Grid item xs={12} lg={8}>
                        <Grid
                            container
                            justifyContent="space-around"
                            alignItems="center"
                            alignContent="center"
                            spacing={1}
                        >
                            <Grid item>
                                <Status
                                    ready={groupingSelected}
                                    className={classes.actions}
                                    name="League Selected"
                                />
                            </Grid>
                            <Grid item>
                                <Status
                                    ready={tournamentReady}
                                    className={classes.actions}
                                    name="Tournament Named"
                                />
                            </Grid>
                            <Grid item>
                                <Status
                                    ready={tournamentReady}
                                    className={classes.actions}
                                    name={stageStatusName}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Button
                            className={classes.actions}
                            variant="contained"
                            color="secondary"
                            size="medium"
                            disabled={
                                !tournamentReady ||
                                !groupingSelected ||
                                !stagesValid ||
                                loading
                            }
                            onClick={handleButtonClick}
                            startIcon={<SaveIcon />}
                        >
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                            {!loading && 'Create'}
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
            {error !== null && (
                <CardContent>
                    <Typography color="secondary">{error}</Typography>
                </CardContent>
            )}
        </Card>
    );
}
