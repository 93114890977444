import axios from 'axios';

export const Query = (url, request, setResponse, setError) => {
    const opts = {
        method: 'post',
        url: url + '/query',
        data: request,
    };
    axios(opts)
        .then(function (response) {
            const resp = response.data;
            resp.tournamentId = request.tournamentId;
            console.log('resp', resp);
            setResponse(resp);
            setError(null);
        })
        .catch(function (error) {
            console.log('setError', error.response, request);
            setResponse(error.response);
        });
};

export const Post = (url, authUser, request, setResponse, setError) => {
    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'post',
            url: url,
            data: request,
            headers: { Authorization: 'Bearer ' + idToken },
        };
        axios(opts)
            .then(function (response) {
                const resp = response.data;
                resp.tournamentId = request.tournamentId;
                console.log('resp', resp);
                setResponse(resp);
                setError(null);
            })
            .catch(function (error) {
                console.log('setError', error.response, request);
                setResponse(error.response);
            });
    });
};

export const Put = (url, authUser, request, setResponse, setError) => {
    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'put',
            url: url,
            data: request,
            headers: { Authorization: 'Bearer ' + idToken },
        };
        axios(opts)
            .then(function (response) {
                const resp = response.data;
                resp.tournamentId = request.tournamentId;
                console.log('resp', resp);
                setResponse(resp);
                setError(null);
            })
            .catch(function (error) {
                console.log('setError', error.response, request);
                setError(error.response);
            });
    });
};

export const Get = (url, authUser, setResponse, setError) => {
    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'get',
            url: url,
            headers: { Authorization: 'Bearer ' + idToken },
        };
        axios(opts)
            .then(function (response) {
                console.log('resp', response.data);
                setResponse(response.data);
                setError(null);
            })
            .catch(function (error) {
                console.log('setError', error.response);
                setError(error.response);
                setResponse(null);
            });
    });
};

export const GetNoAuth = (url, setResponse, setError) => {
    const opts = {
        method: 'get',
        url: url,
    };
    axios(opts)
        .then(function (response) {
            console.log('resp', response.data);
            setResponse(response.data);
            setError(null);
        })
        .catch(function (error) {
            console.log('setError', error.response);
            setError(error.response);
        });
};

export const PostUpdate = (url, setResponse, setError) => {
    const opts = {
        method: 'post',
        url: url + '/update',
        data: {},
    };

    axios(opts)
        .then(function (response) {
            console.log('resp', response.data);
            setResponse(response.data);
            setError(null);
        })
        .catch(function (error) {
            console.log('setError', error.response);
            setError(error.response);
        });
};
