// Firebase
import 'firebase/firestore';

import { AuthContext } from 'context/User';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { DurationPost } from 'request/Tournament/Duration';
import { FormatSeriesPost } from 'request/Tournament/FormatSeries';
import { ManagersPost } from 'request/Tournament/Managers';
import { RoundRobinPost } from 'request/Tournament/RoundRobin';
import { SingleEliminationPost } from 'request/Tournament/SingleElimination';
import { TournamentPostGenerate } from 'request/Tournament/Tournament';

const InitialState = {
    error: null,
    tournament: {
        data: {
            name: '',
            id: '',
        },
    },
    grouping: {
        data: {
            name: 'Not Yet Selected',
            total_ids: 10000000,
            id: '',
            last_transfer_totals: null,
        },
    },
    managers: {
        data: {
            active: false,
            active_last_transfer: 1,
            seeding_method: 'overall_rank',
            max: 10000000,
            name: '',
            seeding_week: 1,
            default: true,
            notPosted: true,
        },
    },
    duration: {
        data: {
            start: 1,
            notPosted: true,
        },
    },
    stage: {
        group: {
            seriesFormat: {
                data: {
                    object: {
                        id: '',
                        gamesMin: 1,
                        gamesMax: 1,
                        totalEntries: 2,
                        totalQualifiers: 1,
                    },
                    hasFormat: {
                        stageRrIds: [
                            {
                                id: '',
                            },
                        ],
                    },
                },
            },
            data: {
                object: {
                    id: '',
                    maxEntriesPerGroup: 4,
                    maxQualifiersPerGroup: 2,
                    minNumberOfRepetitions: 1,
                },
                stageFrom: {
                    stageRrIds: [],
                    StageRrIds: [],
                },
                tournamentId: '',
            },
            enabled: true,
        },
        knockout: {
            seriesFormat: {
                data: {
                    object: {
                        id: '',
                        gamesMin: 1,
                        gamesMax: 1,
                        totalEntries: 2,
                        totalQualifiers: 1,
                    },
                    hasFormat: {
                        stageSeIds: [
                            {
                                id: '',
                            },
                        ],
                    },
                },
            },
            data: {
                object: {
                    id: '',
                    maxEntries: 2,
                    maxEntriesEnabled: false,
                    maxQualifyingRoundPosition: 1,
                },
                stageFrom: {
                    stageRrIds: [],
                    StageRrIds: [],
                },
                tournamentId: '',
            },
            enabled: true,
        },
    },
};

const CreateContext = createContext(null);

const ACTIONS = {
    SET_ERROR: 'setErrors',
    SET_TOURNAMENT_NAME: 'setTournamentName',
    SET_TOURNAMENT: 'setTournament',
    SET_STAGE_GROUP: 'setStageGroup',
    SET_STAGE_GROUP_MAX_ENTRIES_PER_GROUP: 'setStageGroupMaxEntriesPerGroup',
    SET_STAGE_GROUP_MAX_QUALIFIERS_PER_GROUP:
        'setStageGroupMaxQualifiersPerGroup',
    SET_STAGE_GROUP_MIN_NUMBER_OF_REPETITIONS:
        'setStageGroupMinNumberOfRepetitions',
    SET_STAGE_GROUP_SERIES_FORMAT: 'setStageGroupSeriesFormat',
    SET_STAGE_GROUP_SERIES_FORMAT_GAMES: 'setStageGroupSeriesFormatGames',
    SET_STAGE_GROUP_ENABLE_TOGGLE: 'setStageGroupEnableToggle',
    SET_STAGE_KNOCKOUT: 'setStageKnockout',
    SET_STAGE_KNOCKOUT_SERIES_FORMAT: 'setStageKnockoutSeriesFormat',
    SET_STAGE_KNOCKOUT_SERIES_FORMAT_GAMES: 'setStageKnockoutSeriesFormatGames',
    SET_STAGE_KNOCKOUT_ENABLE_TOGGLE: 'setStageKnockoutEnableToggle',
    SET_MANAGERS: 'setManagers',
    SET_MANAGERS_ACTIVE: 'setManagersActive',
    SET_MANAGERS_ACTIVE_LAST_TRANSFER: 'setManagersActiveLastTransfer',
    SET_MANAGERS_SEEDING_WEEK: 'setManagersSeedingWeek',
    SET_MANAGERS_SEEDING_METHOD: 'setManagersSeedingMethod',
    SET_MANAGERS_MAX: 'setManagersMax',
    SET_DURATION: 'setDuration',
    SET_DURATION_START: 'setDurationStart',
    SET_GROUPING: 'setGrouping',
};

function createReducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_TOURNAMENT_NAME:
            if (typeof action.payload !== 'string') {
                throw new Error('tournament name must be string');
            }
            return {
                ...state,
                tournament: {
                    ...state.tournament,
                    data: {
                        ...state.tournament.data,
                        name: action.payload,
                    },
                },
            };
        case ACTIONS.SET_TOURNAMENT:
            return {
                ...state,
                tournament: {
                    ...state.tournament,
                    data: action.payload,
                },
            };
        case ACTIONS.SET_STAGE_GROUP:
            return {
                ...state,
                stage: {
                    ...state.stage,
                    group: {
                        ...state.stage.group,
                        data: action.payload,
                    },
                },
            };
        case ACTIONS.SET_STAGE_GROUP_MAX_ENTRIES_PER_GROUP:
            if (typeof action.payload !== 'number') {
                throw new Error(' name must be number');
            }
            return {
                ...state,
                stage: {
                    ...state.stage,
                    group: {
                        ...state.stage.group,
                        data: {
                            ...state.stage.group.data,
                            object: {
                                ...state.stage.group.data.object,
                                maxEntriesPerGroup: action.payload,
                            },
                        },
                    },
                },
            };
        case ACTIONS.SET_STAGE_GROUP_MAX_QUALIFIERS_PER_GROUP:
            if (typeof action.payload !== 'number') {
                throw new Error(' name must be number');
            }
            return {
                ...state,
                stage: {
                    ...state.stage,
                    group: {
                        ...state.stage.group,
                        data: {
                            ...state.stage.group.data,
                            object: {
                                ...state.stage.group.data.object,
                                maxQualifiersPerGroup: action.payload,
                            },
                        },
                    },
                },
            };
        case ACTIONS.SET_STAGE_GROUP_MIN_NUMBER_OF_REPETITIONS:
            if (typeof action.payload !== 'number') {
                throw new Error(' name must be number');
            }
            return {
                ...state,
                stage: {
                    ...state.stage,
                    group: {
                        ...state.stage.group,
                        data: {
                            ...state.stage.group.data,
                            object: {
                                ...state.stage.group.data.object,

                                minNumberOfRepetitions: action.payload,
                            },
                        },
                    },
                },
            };
        case ACTIONS.SET_STAGE_GROUP_ENABLE_TOGGLE:
            return {
                ...state,
                stage: {
                    ...state.stage,
                    group: {
                        ...state.stage.group,
                        enabled: !state.stage.group.enabled,
                    },
                },
            };
        case ACTIONS.SET_STAGE_GROUP_SERIES_FORMAT:
            return {
                ...state,
                stage: {
                    ...state.stage,
                    group: {
                        ...state.stage.group,
                        seriesFormat: {
                            ...state.stage.group.seriesFormat,
                            data: action.payload,
                        },
                    },
                },
            };
        case ACTIONS.SET_STAGE_GROUP_SERIES_FORMAT_GAMES:
            if (typeof action.payload !== 'number') {
                throw new Error(' name must be number');
            }
            return {
                ...state,
                stage: {
                    ...state.stage,
                    group: {
                        ...state.stage.group,
                        seriesFormat: {
                            ...state.stage.group.seriesFormat,
                            data: {
                                ...state.stage.group.seriesFormat.data,
                                object: {
                                    ...state.stage.group.seriesFormat.data
                                        .object,
                                    gamesMax: action.payload,
                                    gamesMin: action.payload,
                                },
                            },
                        },
                    },
                },
            };
        case ACTIONS.SET_STAGE_KNOCKOUT:
            return {
                ...state,
                stage: {
                    ...state.stage,
                    knockout: {
                        ...state.stage.knockout,
                        data: action.payload,
                    },
                },
            };
        case ACTIONS.SET_STAGE_KNOCKOUT_SERIES_FORMAT_GAMES:
            if (typeof action.payload !== 'number') {
                throw new Error(' name must be number');
            }
            return {
                ...state,
                stage: {
                    ...state.stage,
                    knockout: {
                        ...state.stage.knockout,
                        seriesFormat: {
                            ...state.stage.knockout.seriesFormat,
                            data: {
                                ...state.stage.knockout.seriesFormat.data,
                                object: {
                                    ...state.stage.knockout.seriesFormat.data
                                        .object,
                                    gamesMax: action.payload,
                                    gamesMin: action.payload,
                                },
                            },
                        },
                    },
                },
            };
        case ACTIONS.SET_STAGE_KNOCKOUT_ENABLE_TOGGLE:
            return {
                ...state,
                stage: {
                    ...state.stage,
                    knockout: {
                        ...state.stage.knockout,
                        enabled: !state.stage.knockout.enabled,
                    },
                },
            };
        case ACTIONS.SET_STAGE_KNOCKOUT_SERIES_FORMAT:
            return {
                ...state,
                stage: {
                    ...state.stage,
                    knockout: {
                        ...state.stage.knockout,
                        seriesFormat: {
                            ...state.stage.knockout.seriesFormat,
                            data: action.payload,
                        },
                    },
                },
            };
        case ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case ACTIONS.SET_MANAGERS:
            return {
                ...state,
                managers: {
                    data: action.payload,
                },
            };
        case ACTIONS.SET_MANAGERS_ACTIVE:
            if (typeof action.payload !== 'boolean') {
                throw new Error('active name must be boolean');
            }
            return {
                ...state,
                managers: {
                    ...state.managers,
                    data: {
                        ...state.managers.data,
                        active: action.payload,
                        default: false,
                    },
                },
            };
        case ACTIONS.SET_MANAGERS_ACTIVE_LAST_TRANSFER:
            if (typeof action.payload !== 'number') {
                throw new Error('active_last_transfer name must be number');
            }
            return {
                ...state,
                managers: {
                    ...state.managers,
                    data: {
                        ...state.managers.data,
                        active_last_transfer: action.payload,
                        default: false,
                    },
                },
            };
        case ACTIONS.SET_MANAGERS_SEEDING_WEEK:
            if (typeof action.payload !== 'number') {
                throw new Error(
                    'seeding week must be number',
                    typeof action.payload
                );
            }
            return {
                ...state,
                managers: {
                    ...state.managers,
                    data: {
                        ...state.managers.data,
                        seeding_week: action.payload,
                        default: false,
                    },
                },
            };
        case ACTIONS.SET_MANAGERS_SEEDING_METHOD:
            if (typeof action.payload !== 'string') {
                throw new Error('seeding method must be string');
            }
            return {
                ...state,
                managers: {
                    ...state.managers,
                    data: {
                        ...state.managers.data,
                        seeding_method: action.payload,
                        default: false,
                    },
                },
            };
        case ACTIONS.SET_MANAGERS_MAX:
            if (typeof action.payload !== 'number') {
                throw new Error('max must be number');
            }
            return {
                ...state,
                managers: {
                    ...state.managers,
                    data: {
                        ...state.managers.data,
                        max: action.payload,
                        default: false,
                    },
                },
            };
        case ACTIONS.SET_DURATION:
            return {
                ...state,
                duration: {
                    data: action.payload,
                },
            };
        case ACTIONS.SET_DURATION_START:
            if (typeof action.payload !== 'number') {
                throw new Error('must be number');
            }
            return {
                ...state,
                duration: {
                    ...state.duration,
                    data: {
                        ...state.duration.data,
                        start: action.payload,
                    },
                },
            };
        case ACTIONS.SET_GROUPING:
            return {
                ...state,
                grouping: {
                    data: action.payload,
                },
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const CreateProvidera = ({ children }) => {
    const { authUser } = useContext(AuthContext);

    const [context, dispatch] = useReducer(createReducer, InitialState);
    console.log('Create Context', context);

    const setTournament = (value) => {
        dispatch({
            type: ACTIONS.SET_TOURNAMENT,
            payload: value,
        });
    };

    const setDuration = (value) => {
        dispatch({
            type: ACTIONS.SET_DURATION,
            payload: value,
        });
    };

    const setManagers = (value) => {
        dispatch({
            type: ACTIONS.SET_MANAGERS,
            payload: value,
        });
    };

    const setStageGroup = (value) => {
        dispatch({
            type: ACTIONS.SET_STAGE_GROUP,
            payload: value,
        });
    };

    const setStageGroupSeriesFormat = (value) => {
        dispatch({
            type: ACTIONS.SET_STAGE_GROUP_SERIES_FORMAT,
            payload: value,
        });
    };

    const setStageKnockout = (value) => {
        dispatch({
            type: ACTIONS.SET_STAGE_KNOCKOUT,
            payload: value,
        });
    };

    const setStageKnockoutSeriesFormat = (value) => {
        dispatch({
            type: ACTIONS.SET_STAGE_KNOCKOUT_SERIES_FORMAT,
            payload: value,
        });
    };

    const setError = (error) => {
        dispatch({
            type: ACTIONS.SET_ERROR,
            payload: error,
        });
    };

    // create duration
    useEffect(() => {
        if (
            context.tournament.data.id !== '' &&
            context.duration.data.notPosted
        ) {
            DurationPost(
                authUser,
                context.duration.data,
                setDuration,
                context.tournament.data,
                setError
            );
        }
    }, [context.tournament.data.id]);

    // create managers
    useEffect(() => {
        if (
            context.tournament.data.id !== '' &&
            context.managers.data.notPosted
        ) {
            ManagersPost(
                authUser,
                context.managers.data,
                setManagers,
                context.tournament.data,
                setError
            );
        }
    }, [context.tournament.data.id]);

    // create group stage
    useEffect(() => {
        if (
            context.tournament.data.id !== '' &&
            context.stage.group.enabled &&
            context.stage.group.data.object.id === ''
        ) {
            const request = {
                ...context.stage.group.data,
                tournamentId: context.tournament.data.id,
            };
            RoundRobinPost(authUser, request, setStageGroup, setError);
        }
    }, [context.tournament.data.id]);

    // create group stage format
    useEffect(() => {
        if (
            context.stage.group.data.object.id !== '' &&
            context.stage.group.seriesFormat.data.object.id === ''
        ) {
            const request = {
                ...context.stage.group.seriesFormat.data,
                hasFormat: {
                    stageRrIds: [
                        {
                            id: context.stage.group.data.object.id,
                        },
                    ],
                },
                tournamentId: context.tournament.data.id,
            };
            FormatSeriesPost(
                authUser,
                request,
                setStageGroupSeriesFormat,
                setError
            );
        }
    }, [context.stage.group.data.object.id]);

    // create kncokout stage
    useEffect(() => {
        if (
            context.tournament.data.id !== '' &&
            context.stage.knockout.enabled &&
            context.stage.knockout.data.object.id === '' &&
            ((context.stage.group.data.object.id !== '' &&
                context.stage.group.enabled &&
                context.stage.knockout.data.object.id === '') ||
                !context.stage.group.enabled)
        ) {
            const stageRrIds = [];

            if (context.stage.group.enabled) {
                stageRrIds.push({
                    id: context.stage.group.data.object.id,
                });
            }

            const request = {
                ...context.stage.knockout.data,
                tournamentId: context.tournament.data.id,
                stageFrom: {
                    stageRrIds: stageRrIds,
                },
            };
            SingleEliminationPost(
                authUser,
                request,
                setStageKnockout,
                setError
            );
        }
    }, [
        context.tournament.data.id,
        context.stage.group.seriesFormat.data.object.id,
    ]);

    // create knockout stage format
    useEffect(() => {
        if (
            context.stage.knockout.data.object.id !== '' &&
            context.stage.knockout.seriesFormat.data.object.id === ''
        ) {
            const request = {
                ...context.stage.knockout.seriesFormat.data,
                hasFormat: {
                    stageSeIds: [
                        {
                            id: context.stage.knockout.data.object.id,
                        },
                    ],
                },
                tournamentId: context.tournament.data.id,
            };

            console.log(request);
            FormatSeriesPost(
                authUser,
                request,
                setStageKnockoutSeriesFormat,
                setError
            );
        }
    }, [context.stage.knockout.data.object.id]);

    // create knockout stage format
    useEffect(() => {
        if (shouldCompute(context)) {
            const request = {
                ...context.stage.knockout.seriesFormat.data,
                hasFormat: {
                    stageSeIds: [
                        {
                            id: context.stage.knockout.data.object.id,
                        },
                    ],
                },
                tournamentId: context.tournament.data.id,
            };

            console.log(request);
            TournamentPostGenerate(
                authUser,
                context.tournament.data,
                setTournament,
                setError
            );
        }
    }, [
        context.stage.group.seriesFormat.data.object.id,
        context.stage.knockout.seriesFormat.data.object.id,
    ]);

    return (
        <CreateContext.Provider value={{ context, dispatch }}>
            {children}
        </CreateContext.Provider>
    );
};

function shouldCompute(context) {
    // tournament exists
    if (context.tournament.data.id === '') {
        console.log('missing tournament');
        return false;
    }
    // group stage exists
    if (
        context.stage.group.data.object.id === '' &&
        context.stage.group.enabled
    ) {
        console.log('missing group stage');
        return false;
    }
    // group stage format exists

    if (
        context.stage.group.seriesFormat.data.object.id === '' &&
        context.stage.group.enabled
    ) {
        console.log('missing group stage series format');

        return false;
    }
    // knockout stage exists

    if (
        context.stage.knockout.data.object.id === '' &&
        context.stage.knockout.enabled
    ) {
        console.log('missing knockout stage');

        return false;
    }
    // knockout stage format exists
    if (
        context.stage.knockout.seriesFormat.data.object.id === '' &&
        context.stage.knockout.enabled
    ) {
        console.log('missing knockout stage series format');

        return false;
    }
    // duration
    if (context.duration.data.notPosted === true) {
        console.log('missing duration');

        return false;
    }
    // managers
    if (context.managers.data.notPosted === true) {
        console.log('missing managers');
        return false;
    }

    return true;
}

function useCreate() {
    const context = useContext(CreateContext);
    if (context === undefined) {
        throw new Error('useCreate must be used within a CreateProvider');
    }
    return context;
}

function useStageGroup() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error('useStageGroup must be used within a CreateProvider');
    }
    return context.stage.group.data;
}

function stageGroupEnabled() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error(
            'stageGroupEnabled must be used within a CreateProvider'
        );
    }
    return context.stage.group.enabled;
}

function useStageGroupSeriesFormat() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error('useStageGroup must be used within a CreateProvider');
    }
    return context.stage.group.seriesFormat.data;
}

function useStageKnockout() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error(
            'useStageKnockout must be used within a CreateProvider'
        );
    }
    return context.stage.knockout.data;
}

function stageKnockoutEnabled() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error(
            'stageKnockoutEnabled must be used within a CreateProvider'
        );
    }
    return context.stage.knockout.enabled;
}

function useStageKnockoutSeriesFormat() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error(
            'useStageKnockoutSeriesFormat must be used within a CreateProvider'
        );
    }
    return context.stage.knockout.seriesFormat.data;
}

function useError() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error('useError must be used within a CreateProvider');
    }
    return context.error;
}

function useTournament() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error('useTournament must be used within a CreateProvider');
    }
    return context.tournament.data;
}

function useGrouping() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error('useGrouping must be used within a CreateProvider');
    }
    return context.grouping.data;
}

function useManagers() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error('useManagers must be used within a CreateProvider');
    }
    return context.managers.data;
}

function useDuration() {
    const { context } = useContext(CreateContext);
    if (context === undefined) {
        throw new Error('useDuration must be used within a CreateProvider');
    }
    return context.duration.data;
}

export {
    ACTIONS,
    CreateContext,
    CreateProvidera,
    stageGroupEnabled,
    stageKnockoutEnabled,
    useCreate,
    useDuration,
    useError,
    useGrouping,
    useManagers,
    useStageGroup,
    useStageGroupSeriesFormat,
    useStageKnockout,
    useStageKnockoutSeriesFormat,
    useTournament,
};
