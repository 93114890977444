import { FormControl, FormHelperText, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    ACTIONS,
    useCreate,
    useGrouping,
    useManagers,
} from 'context/create/Provider';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function VerticalLinearStepper() {
    const classes = useStyles();
    const { dispatch } = useCreate();
    const managers = useManagers();
    const grouping = useGrouping();

    const handleSliderChange = (event, newValue) => {
        dispatch({
            type: ACTIONS.SET_MANAGERS_MAX,
            payload: newValue,
        });
    };

    return (
        <div className={classes.root}>
            <FormControl className={classes.root}>
                <Slider
                    value={managers.max}
                    defaultValue={60}
                    onChange={handleSliderChange}
                    aria-labelledby="discrete-slider-always"
                    valueLabelDisplay="on"
                    min={2}
                    max={grouping.total_ids}
                    step={1}
                />
                <FormHelperText>
                    Maximium amount of managers in a tournament
                </FormHelperText>
            </FormControl>
        </div>
    );
}
