// Firebase
import 'firebase/firestore';

import app from 'App/Base.js';
import { ViewTournamentContext } from 'context/view/tournament';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const DurationContext = createContext(null);

const db = app.firestore();
export const DurationContextProvider = ({ children }) => {
    const { tournament } = useContext(ViewTournamentContext);
    const [duration, setDuration] = useState({
        start: 0,
    });

    // when the saved entry id changes, update the entry object
    useEffect(() => {
        if (tournament.object.id !== '') {
            db.collection('tournament')
                .doc(tournament.object.id)
                .collection('metadata')
                .doc('duration')
                .onSnapshot(function (doc) {
                    console.log('Document data:', doc.data());
                    setDuration(doc.data());
                });
        }
    }, [tournament.object.id]);

    return (
        <DurationContext.Provider
            value={{
                duration,
                setDuration,
            }}
        >
            {children}
        </DurationContext.Provider>
    );
};
