import { TournamentContext } from 'context/Create';
import { RequestFormatSeriesProvider } from 'context/format/RequestFormatSeries';
import { AuthContext } from 'context/User';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { RoundRobinPost, RoundRobinPut } from 'request/Tournament/RoundRobin';

export const RoundRobinContext = createContext(null);
export const RoundRobinErrorContext = createContext(null);

export const RequestStageRRProvider = ({ children }) => {
    const { tournament } = useContext(TournamentContext);
    const { authUser } = useContext(AuthContext);

    const [roundRobin, setRoundRobin] = useState({
        object: {
            id: '',
            maxEntriesPerGroup: 4,
            maxQualifiersPerGroup: 2,
            minNumberOfRepetitions: 1,
        },
        stageFrom: {
            stageRrIds: [],
            StageRrIds: [],
        },
        tournamentId: '',
        editing: true,
    });
    const [roundRobinError, setRoundRobinError] = useState(null);

    useEffect(() => {
        if (tournament.id !== '' && typeof tournament.id === 'string') {
            setRoundRobin({ ...roundRobin, tournamentId: tournament.id });
        }
    }, [tournament.id]);

    useEffect(() => {
        if (roundRobin.tournamentId !== '' && roundRobin.editing === false) {
            if (roundRobin.object.id === '') {
                RoundRobinPost(
                    authUser,
                    roundRobin,
                    setRoundRobin,
                    setRoundRobinError
                );
            } else {
                RoundRobinPut(
                    authUser,
                    roundRobin,
                    setRoundRobin,
                    setRoundRobinError
                );
            }
        }
    }, [
        roundRobin.object.maxEntriesPerGroup,
        roundRobin.object.maxQualifiersPerGroup,
        roundRobin.object.minNumberOfRepetitions,
        roundRobin.tournamentId,
        roundRobin.editing,
    ]);

    return (
        <RequestFormatSeriesProvider>
            <RoundRobinContext.Provider
                value={{
                    roundRobin,
                    setRoundRobin,
                }}
            >
                <RoundRobinErrorContext.Provider
                    value={{
                        roundRobinError,
                        setRoundRobinError,
                    }}
                >
                    {children}
                </RoundRobinErrorContext.Provider>
            </RoundRobinContext.Provider>
        </RequestFormatSeriesProvider>
    );
};
