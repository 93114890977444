import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import validate from 'validate.js';

import constraints from '../../constraints';
import AuthProviderList from '../../layout/AuthProviderList/AuthProviderList';

const initialState = {
    emailAddress: '',
    password: '',

    errors: null,
};

class SignInDialog extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;
    }

    signIn = () => {
        const { emailAddress, password } = this.state;

        const errors = validate(
            {
                emailAddress: emailAddress,
                password: password,
            },
            {
                emailAddress: constraints.emailAddress,
                password: constraints.password,
            }
        );

        if (errors) {
            this.setState({ errors });
        } else {
            this.setState(
                {
                    errors: null,
                },
                () => {
                    this.props.signIn(emailAddress, password);
                }
            );
        }
    };

    handleExited = () => {
        this.setState(initialState);
    };

    handleKeyPress = (event) => {
        const key = event.key;

        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
            return;
        }

        if (key === 'Enter') {
            this.signIn();
        }
    };

    handleEmailAddressChange = (event) => {
        const emailAddress = event.target.value;

        this.setState({ emailAddress });
    };

    handlePasswordChange = (event) => {
        const password = event.target.value;

        this.setState({ password });
    };

    handleSignInClick = () => {
        this.signIn();
    };

    render() {
        // Properties
        const { fullScreen, open, isPerformingAuthAction } = this.props;

        // Events
        const { onClose, onAuthProviderClick } = this.props;

        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={onClose}
                onExited={this.handleExited}
                onKeyPress={this.handleKeyPress}
            >
                <DialogTitle>Sign In / Up with Google!</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Google Authorization is only supported at this time.
                    </DialogContentText>
                    <DialogContentText>
                        <b>fplplugin</b> will not store any more information
                        than your email and your fantasy premier league user id.
                        These are used solely so that tournaments can be easily
                        accessible for the user.
                    </DialogContentText>

                    <AuthProviderList
                        isPerformingAuthAction={isPerformingAuthAction}
                        onAuthProviderClick={onAuthProviderClick}
                    />
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SignInDialog.propTypes = {
    fullScreen: PropTypes.bool,
    open: PropTypes.bool.isRequired,

    isPerformingAuthAction: PropTypes.bool.isRequired,

    signIn: PropTypes.func.isRequired,

    onClose: PropTypes.func.isRequired,
    onAuthProviderClick: PropTypes.func.isRequired,
};

export default SignInDialog;
