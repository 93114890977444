import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { LoginCallToAction } from 'containers/login/callToAction';
import { AuthContext } from 'context/User';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

export default function heroButton() {
    const { authUser } = useContext(AuthContext);
    const theme = useTheme();
    const history = useHistory();
    const routeCreate = () => {
        const path = `/create`;
        history.push(path);
    };

    return (
        <div>
            {authUser === null && <LoginCallToAction />}
            {authUser !== null && (
                <Button
                    variant="contained"
                    style={{
                        color: theme.palette.primary.contrastText,
                        backgroundColor: theme.palette.primary.main,
                        fontSize: 30,
                    }}
                    onClick={routeCreate}
                >
                    Create League
                </Button>
            )}
        </div>
    );
}
// export default heroButton;
