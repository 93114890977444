import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { Redirect } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.type === 'dark' ? '#303030' : '#fafafa',
    },
}));

export default function Footer({ children }) {
    const [policy, setPolicy] = React.useState(null);
    const classes = useStyles();

    if (policy === true) {
        if (window.location.pathname === '/policy') {
            setPolicy(null);
        }
        return <Redirect to="/policy" />;
    }

    return (
        <div>
            {children}
            <div className={classes.root}>
                <div className="container">
                    <hr />
                    <div className="row">
                        <p className={classes.root}>
                            &copy;{new Date().getFullYear()} FPL Plugin |
                            Contact us at: team@fplplugin.com |
                            <Button
                                onClick={() => {
                                    setPolicy(true);
                                }}
                                className={classes.root}
                            >
                                Privacy
                            </Button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
