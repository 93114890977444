import {
    Get,
    GetNoAuth,
    Post,
    PostUpdate,
    Put,
    Query,
} from 'request/Tournament/Base';
import { urlWithID, urlWithTournament } from 'request/Tournament/utl';

const postfix = 'stage/se';

export const SingleEliminationQuery = (request, setResponse, setError) => {
    return Query(
        urlWithTournament(postfix, request.tournamentId),
        request,
        setResponse,
        setError
    );
};

export const SingleEliminationPost = (
    authUser,
    request,
    setResponse,
    setError
) => {
    return Post(
        urlWithTournament(postfix, request.tournamentId),
        authUser,
        request,
        setResponse,
        setError
    );
};
export const SingleEliminationPut = (
    authUser,
    request,
    setResponse,
    setError
) => {
    return Put(
        urlWithID(postfix, request.tournamentId, request.object.id),
        authUser,
        request,
        setResponse,
        setError
    );
};

export const SingleEliminationGet = (
    authUser,
    tournamentid,
    id,
    setResponse,
    setError
) => {
    return Get(
        urlWithID(postfix, tournamentid, id),
        authUser,
        setResponse,
        setError
    );
};

export const SingleEliminationGetNoAuth = (
    tournamentid,
    id,
    setResponse,
    setError
) => {
    return GetNoAuth(
        urlWithID(postfix, tournamentid, id),
        setResponse,
        setError
    );
};

export const SingleEliminationUpdate = (
    tournamentid,
    id,
    setResponse,
    setError
) => {
    return PostUpdate(
        urlWithID(postfix, tournamentid, id),
        setResponse,
        setError
    );
};
