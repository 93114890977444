import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function VerticalLinearStepper({
    disabledFunc,
    max,
    min,
    disabled,
    value,
    handleChange,
    formHelperText,
    inputLabel,
}) {
    const classes = useStyles();
    const items = [];

    for (let i = min; i <= max; i++) {
        items.push(
            <MenuItem key={i} value={i} disabled={disabledFunc(i)}>
                <Typography>{i}</Typography>
            </MenuItem>
        );
    }
    return (
        <div className={classes.root}>
            <FormControl className={classes.root}>
                <InputLabel id="demo-simple-select-helper-label">
                    {inputLabel}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    color="primary"
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                >
                    {items}
                </Select>
                <FormHelperText>{formHelperText}</FormHelperText>
            </FormControl>
        </div>
    );
}
