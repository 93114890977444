import { ViewTournamentContext } from 'context/view/tournament';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { GameGetNoAuth } from 'request/Tournament/Game';
import { ScoreGet } from 'request/Tournament/Score';

export const ViewGameContext = createContext(null);

export const ViewGameProvider = (props) => {
    const { children } = props;
    const { tournament } = useContext(ViewTournamentContext);
    const [game, setGame] = useState({
        object: {
            id: props.id,
        },
        base: {
            entryList: [],
            scoreList: [],
            status: {
                value: '',
            },
        },
    });

    const [gameScore, setGameScore] = useState({
        object: {
            id: '',
            rows: {},
            table: [],
        },
        base: {
            inboundHas: {
                tournamentIds: [],
                stageSeIds: [],
                stageRrIds: [],
                groupIds: [],
                roundIds: [],
                seriesIds: [],
                gameIds: [],
            },
        },
    });
    console.log('gameScore', gameScore);

    const setError = (error) => {
        console.log(error);
    };

    useEffect(() => {
        if (game.object.id !== '') {
            GameGetNoAuth(
                tournament.object.id,
                game.object.id,
                setGame,
                setError
            );
        }
    }, [game.object.id]);

    useEffect(() => {
        if (game.base.scoreList.length > 0) {
            ScoreGet(
                tournament.object.id,
                game.base.scoreList[game.base.scoreList.length - 1].id,
                setGameScore,
                setError
            );
        }
    }, [game.base.scoreList]);

    const getGameWeek = (entryID, tournamentStart) => {
        for (let index = 0; index < game.base.entryList.length; index++) {
            if (game.base.entryList[index].id === entryID) {
                if (!game.base.entryList[index].position) {
                    return tournamentStart;
                }
                return game.base.entryList[index].position + tournamentStart;
            }
        }
        return tournamentStart;
    };

    return (
        <ViewGameContext.Provider
            value={{
                game,
                setGame,
                gameScore,
                getGameWeek,
            }}
        >
            {children}
        </ViewGameContext.Provider>
    );
};
