import { Get, Post, Put } from 'request/Tournament/Base';
import { urlWithID, urlWithTournament } from 'request/Tournament/utl';

const postfix = 'format/series';

export const FormatSeriesPost = (authUser, request, setResponse, setError) => {
    return Post(
        urlWithTournament(postfix, request.tournamentId),
        authUser,
        request,
        setResponse,
        setError
    );
};
export const FormatSeriesPut = (authUser, request, setResponse, setError) => {
    return Put(
        urlWithID(postfix, request.tournamentId, request.object.id),
        authUser,
        request,
        setResponse,
        setError
    );
};

export const FormatSeriesGet = (authUser, request, setResponse, setError) => {
    return Get(
        urlWithID(postfix, request.tournamentId, request.object.id),
        authUser,
        request,
        setResponse,
        setError
    );
};
