import './Landing.css';

import { Grid } from '@material-ui/core';
import HeroButton from 'atomic/organisms/heroButton';
import JoinMiniLeagueButton from 'atomic/organisms/joinMiniLeagueButton';
import TwitterButton from 'atomic/organisms/twitterButton';
import React from 'react';
// import theme from '../themes/fplplugin-light-theme';

export default function Landing() {
    // the business end
    return (
        <Grid className="hero-container">
            <h1>FPL Plugin</h1>
            <p>
                Custom formats and structures for your FPL league is only a few
                clicks away
            </p>
            <HeroButton />
            <row>
                <JoinMiniLeagueButton />
                <p2> </p2>
                <TwitterButton />
            </row>
            <p1>Contact us at: team@fplplugin.com</p1>
        </Grid>
    );
}
