import 'firebase/firestore';

import { Avatar, Card } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
    QueryRequestContext,
    QueryRequestProvider,
} from 'context/query/request';
import { QueryResponseProvider } from 'context/query/response';
import { ViewSEContext } from 'context/view/se';
import { ViewTournamentContext } from 'context/view/tournament';
import ViewLayoutRoundList from 'layout/view/round/list';
import { TableLarge } from 'mdi-material-ui';
import React, { useContext } from 'react';
import { RoundQuery } from 'request/Tournament/Round';

export default function ViewLayoutSE() {
    const { tournament } = useContext(ViewTournamentContext);
    const { se } = useContext(ViewSEContext);
    const { queryRequest } = useContext(QueryRequestContext);

    const outboundOf = {
        stageSeIds: [{ id: se.object.id }],
    };

    if (se === null) {
        return <div />;
    }

    return (
        <div>
            <Card style={{ marginBottom: '20px' }}>
                <CardHeader
                    title={'Knockout Stage'}
                    subheader={
                        (se.base.status.value === 'Active' && (
                            <Typography variant="caption" key="total_entries">
                                Total Entries: {se.base.entriesCount}
                            </Typography>
                        )) || (
                            <Typography variant="caption" key="status">
                                Status: {se.base.status.value}
                            </Typography>
                        )
                    }
                    // subheader={(typeof this.state.stage !== "undefined") && (<Typography variant="caption">Start Week: {this.state.stage.duration.start} | Final Week: {this.state.stage.duration.end}<br />Qualifiers Per Group: {this.state.stage.format.qualifiers}</Typography>)}
                    avatar={
                        <Avatar
                            aria-label="recipe"
                            style={{ backgroundColor: 'rgb(55, 0, 60)' }}
                        >
                            <TableLarge />
                        </Avatar>
                    }
                    // action={<IconButton aria-label="settings"
                    // onClick={this.hideAll}
                    // ><ExpandMoreIcon /></IconButton>}
                />
            </Card>
            {/* <Grid container justifyContent="space-around" style={{ marginTop: "20px" }}>
            <Grid item><CircularProgress /></Grid>
        </Grid> */}
            {/* <Grid container justifyContent="space-evenly">
            {(this.state.visible) && (currentGroups)}
        </Grid> */}
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                spacing={2}
            >
                <Grid item xs={12} md={5}>
                    <QueryRequestProvider
                        tournamentID={tournament.object.id}
                        outboundOf={outboundOf}
                        entryIds={queryRequest.entryIds}
                    >
                        <QueryResponseProvider QueryFunc={RoundQuery}>
                            <ViewLayoutRoundList startWeek={1} />
                        </QueryResponseProvider>
                    </QueryRequestProvider>
                </Grid>
            </Grid>
        </div>
    );
}
