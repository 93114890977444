import { Tab } from '@material-ui/core';
import { TabList } from '@material-ui/lab';
import TabLabel from 'componets/backdrop/tab';
import { Tournament } from 'mdi-material-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const BarTabs = ({ currentTab, setCurrentTab }) => {
    const history = useHistory();

    //   const { authUser } = useContext(AuthContext);
    console.log('history', history);
    if (history.location.pathname === '/' && currentTab !== '1') {
        setCurrentTab('1');
    }

    if (history.location.pathname.includes('tour/') && currentTab !== '1') {
        setCurrentTab('1');
    }

    if (history.location.pathname === '/create' && currentTab !== '2') {
        setCurrentTab('2');
    }

    if (history.location.pathname === '/podcast' && currentTab !== '3') {
        setCurrentTab('3');
    }

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
        switch (newValue) {
            case '1':
                history.push('/');
                break;
            case '2':
                history.push('/create');
                break;
            default:
                console.log(`Sorry, we are out of ${newValue}.`);
        }
    };

    return (
        <TabList
            variant="fullWidth"
            onChange={handleChange}
            aria-label="simple tabs example"
            TabIndicatorProps={{
                style: {
                    display: 'none',
                },
            }}
        >
            <Tab
                label={
                    <TabLabel
                        icon={
                            <Tournament
                                style={{ marginTop: 5 }}
                                fontSize="small"
                            />
                        }
                        title="View"
                        active={currentTab === '1'}
                    />
                }
                value="1"
                style={{ minWidth: 100 }}
            />

            <Tab
                label={
                    <TabLabel
                        icon={
                            <Tournament
                                style={{ marginTop: 5 }}
                                fontSize="small"
                            />
                        }
                        title="New"
                        active={currentTab === '2'}
                        value="2"
                    />
                }
                style={{ minWidth: 100 }}
                value="2"
            />
        </TabList>
    );
};

export default BarTabs;
