import 'firebase/firestore';

import { Paper } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React from 'react';

import GameGridTwo from './gameGridTwo';
import GameHeading from './gameHeading';

const MyPaper = styled(Paper)({
    borderRadius: 10,
    border: '2px solid',
    backgroundColor: '#FFF',
    padding: 0,
});

export default function GameComponent({
    game,
    gameScore,
    start,
    totalGames,
    gameWeek,
    totalQualifiers,
}) {
    return (
        <div>
            <MyPaper>
                <GameHeading
                    position={game.object.position}
                    totalGames={totalGames}
                    start={start}
                ></GameHeading>
                <GameGridTwo
                    entryList={game.base.entryList}
                    table={gameScore.object.table}
                    id={game.object.id}
                    gameWeek={gameWeek}
                    totalQualifiers={totalQualifiers}
                ></GameGridTwo>
            </MyPaper>
        </div>
    );
}

// const calculatePosition = (table, index) => {
//   let previousPoints = 0
//   let previousPosition = 0
//   for (let iterrator = 0; iterrator < table.length; iterrator++) {
//     const currentPointsGame = table[iterrator].pointsGame ? table[iterrator].pointsGame : 0

//     if (iterrator === 0) {
//       previousPosition++
//       if (table[index].entryId == table[iterrator].entryId) {
//         return previousPosition
//       } else {
//         previousPoints = currentPointsGame
//       }
//     } else {
//       if (previousPoints > currentPointsGame) {
//         previousPoints = currentPointsGame
//         previousPosition++
//       }
//       if (table[index].entryId == table[iterrator].entryId) {
//         return previousPosition
//       }
//     }
//   }
//   return previousPosition
// }
