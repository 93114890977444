import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import Footer from 'content/Footer/Footer';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    title: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
}));

export default function CenteredGrid({ children }) {
    const classes = useStyles();

    return (
        <Footer>
            <div className={classes.root}>
                <Container maxWidth="sm">
                    <Grid container spacing={2}>
                        <h1 className={classes.title}>Privacy Policy</h1>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h2>Introduction</h2>
                                <p1>
                                    Welcome to FPL Plugin. This Site is operated
                                    by FPL Plugin and has been created to
                                    provide information about FPL PLugin and the
                                    services we provide through the Site,
                                    through any mobile applications, and related
                                    services (collectively, such services,
                                    including any new features and applications,
                                    and the Site, are the “Services”) to our
                                    users and site visitors. This Privacy Policy
                                    describes how FPL Plugin collects, uses and
                                    discloses information, and what choices you
                                    have with respect to that information.
                                </p1>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h2>Information We Collect</h2>
                                <p1>
                                    When you interact with us through our
                                    service, we may collect the following
                                    information from you:
                                    <ul>
                                        <li>Fantasy Premier LeagueTeam ID</li>
                                        <li>User ID</li>
                                        <li>Email Address</li>
                                    </ul>
                                </p1>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h2>Use of information</h2>
                                <p1>
                                    FPL Plugin only uses your information in a
                                    manner that is consistent with the use of
                                    our service and to enrich user experience.
                                    We may reach out to users via email to
                                    inform them of public competitions and
                                    knockout leagues with potential prizes for
                                    taking part. You may be removed from the
                                    email list at any time, if requested.
                                </p1>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h2>Disclosure of Information</h2>
                                <p1>
                                    FPL Plugin is not in the business of selling
                                    your information. We consider this
                                    information to be a vital part of our
                                    relationship with you. We work with third
                                    parties to provide some of our Services. For
                                    example, our Third-Party Hosting Providers.
                                    These providers are bound by their Privacy
                                    Policies to safeguard that information. We
                                    will not share your information with any
                                    third parties not directly involved in the
                                    delivery of our service.
                                </p1>
                            </Paper>
                        </Grid>
                    </Grid>
                    {children}
                </Container>
            </div>
        </Footer>
    );
}
