import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/performance';

import firebase from 'firebase/app';
import settings from 'settings';

const app = firebase.initializeApp(settings.credentials.firebase);
firebase.analytics();

export default app;
