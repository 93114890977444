import { Box } from '@material-ui/core';
import BackdropItem from 'componets/backdrop/item/item';
import CreateDialog from 'componets/create/createDialog';
import SingleElimination from 'containers/create/SingleElimination';
import {
    stageKnockoutEnabled,
    useError,
    useStageKnockoutSeriesFormat,
} from 'context/create/Provider';
import { TrophyOutline } from 'mdi-material-ui';
import React, { useState } from 'react';

export const KnockoutStage = () => {
    const [open, setOpen] = useState(false);
    const error = useError();
    const formatSeries = useStageKnockoutSeriesFormat();
    const enabled = stageKnockoutEnabled();

    const onSave = () => {
        setOpen(false);
    };

    const name = enabled
        ? 'BO' + formatSeries.object.gamesMax
        : 'No Knockout Stage';
    return (
        <Box>
            <BackdropItem
                icon={<TrophyOutline />}
                placeholder={'Configure Knockout Stage'}
                placeholderActive={false}
                value={name}
                disabled={false}
                error={error}
                onClick={() => setOpen(true)}
            />
            <CreateDialog open={open} onClose={() => setOpen(false)}>
                <SingleElimination handleSave={onSave} />
            </CreateDialog>
        </Box>
    );
};

export default KnockoutStage;
