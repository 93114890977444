import { Get, GetNoAuth, Post, Put, Query } from 'request/Tournament/Base';
import { urlWithID, urlWithTournament } from 'request/Tournament/utl';

const postfix = 'round';

export const RoundQuery = (request, setResponse, setError) => {
    return Query(
        urlWithTournament(postfix, request.tournamentId),
        request,
        setResponse,
        setError
    );
};

export const RoundPost = (authUser, request, setResponse, setError) => {
    return Post(
        urlWithTournament(postfix, request.tournamentId),
        authUser,
        request,
        setResponse,
        setError
    );
};
export const RoundPut = (authUser, request, setResponse, setError) => {
    return Put(
        urlWithID(postfix, request.tournamentId, request.object.id),
        authUser,
        request,
        setResponse,
        setError
    );
};

export const RoundGet = (authUser, tournamentid, id, setResponse, setError) => {
    return Get(
        urlWithID(postfix, tournamentid, id),
        authUser,
        setResponse,
        setError
    );
};

export const RoundGetNoAuth = (tournamentid, id, setResponse, setError) => {
    return GetNoAuth(
        urlWithID(postfix, tournamentid, id),
        setResponse,
        setError
    );
};
