import {
    Box,
    CardActionArea,
    Divider,
    Grid,
    Tooltip,
    Typography,
} from '@material-ui/core';
import ViewLayoutName from 'layout/view/name';
import { NumberWithCommas } from 'layout/view/utl';
import React from 'react';

const urlPrefix = 'https://fantasy.premierleague.com/entry';
const urlPostfixHistory = 'history';
const urlPostfixGameweek = 'event';

const standingBorder = { borderLeft: '1px solid #eeeeee' };

const ChampionsLeagueColor = '#0E1E5B';
const EuropaLeagueColor = '#f39200';
const UEL2LeagueColor = '#008000';

export const Standings = (props) => {
    const { score, color, maxQualifiers, totalEntries, gameWeek } = props;
    const groupQualifers =
        maxQualifiers >= totalEntries ? totalEntries - 1 : maxQualifiers;
    const urlPostfix = gameWeek
        ? urlPostfixGameweek + '/' + gameWeek
        : urlPostfixHistory;
    console.log('score.object.table', score.object.table);
    return (
        <Box>
            <Grid
                container
                justifyContent="space-around"
                key={0}
                style={{ borderLeft: '2px solid transparent', padding: '1px' }}
            >
                <Grid item xs={6}>
                    <Typography variant="body2">Manager</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip
                        title={'Total FPL points: decides points tie breakers'}
                        enterTouchDelay={0}
                        key={4}
                    >
                        <Typography
                            variant="body2"
                            align="center"
                            style={standingBorder}
                        >
                            +
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={3}>
                    <Tooltip
                        title={
                            'Points on the bench: decides total FPL points tie breakers'
                        }
                        enterTouchDelay={0}
                        key={4}
                    >
                        <Typography
                            variant="body2"
                            align="center"
                            style={standingBorder}
                        >
                            Bench
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip
                        title={
                            'Points: the ' +
                            groupQualifers +
                            ' team(s) with the most enter the Knockout Stage'
                        }
                        enterTouchDelay={0}
                        key={5}
                    >
                        <Typography
                            variant="body2"
                            align="center"
                            style={standingBorder}
                        >
                            Pts
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
            {score.object.table.map((item, index) => (
                <CardActionArea
                    onClick={() =>
                        window.open(
                            `${urlPrefix}/${item.entryId}/${urlPostfix}`,
                            '_blank'
                        )
                    }
                    key={item.entryId}
                >
                    <Divider />
                    <Grid
                        container
                        justifyContent="space-between"
                        style={
                            (index < groupQualifers && {
                                borderLeft:
                                    '2px solid ' + colorSelector(color, index),
                                paddingLeft: '1px',
                            }) || {
                                borderLeft: '2px solid transparent',
                                paddingLeft: '1px',
                            }
                        }
                    >
                        <Grid item xs={6}>
                            {(index >= groupQualifers && (
                                <Typography
                                    variant="body2"
                                    style={{ padding: '1px' }}
                                    noWrap
                                >
                                    <ViewLayoutName
                                        entry_id={item.entryId}
                                    ></ViewLayoutName>
                                </Typography>
                            )) || (
                                <Tooltip
                                    title={tooltipSelector(index)}
                                    enterTouchDelay={0}
                                    key={4}
                                >
                                    <Typography
                                        variant="body2"
                                        style={{ padding: '1px' }}
                                        noWrap
                                    >
                                        <ViewLayoutName
                                            entry_id={item.entryId}
                                        ></ViewLayoutName>
                                    </Typography>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" align="center">
                                {(item.values.points === undefined && '0') ||
                                    NumberWithCommas(item.values.points)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" align="center">
                                {(item.values.points_on_bench === undefined &&
                                    '0') ||
                                    NumberWithCommas(
                                        item.values.points_on_bench
                                    )}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body2" align="center">
                                {(item.pointsGame === undefined &&
                                    item.pointsSeries === undefined &&
                                    '0') ||
                                    (item.pointsGame === undefined &&
                                        item.pointsSeries) ||
                                    item.pointsGame}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActionArea>
            ))}
        </Box>
    );
};

const colorSelector = (groupColor, index) => {
    switch (index) {
        case 0:
            // code block
            return ChampionsLeagueColor;
        case 1:
            // code block
            return EuropaLeagueColor;
        case 2:
            // code block
            return UEL2LeagueColor;
        default:
            return groupColor;
        // code block
    }
};

const tooltipSelector = (index) => {
    switch (index) {
        case 0:
            // code block
            return '1st seed Qualifies to the `Champions League`';
        case 1:
            // code block
            return '2nd seed Qualifies to the `Europa League`';
        case 2:
            // code block
            return '3rd seed Qualifies to the `Europa Conference League`';
        default:
            return 'Qualifies to the next stage';
        // code block
    }
};
