import axios from 'axios';

const url = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE + '/tournament';

const urlWithID = (tournamentID) => {
    return url + '/' + tournamentID + '/managers';
};

export const ManagersPost = (
    authUser,
    managers,
    setManagers,
    tournament,
    setManagersError
) => {
    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'post',
            url: urlWithID(tournament.id),
            data: managers,
            headers: { Authorization: 'Bearer ' + idToken },
        };

        axios(opts)
            .then(function (response) {
                setManagers(response.data);
                setManagersError(null);
            })
            .catch(function (error) {
                console.log('setManagersError', error.response, managers);
                setManagersError(error.response);
            });
    });
};
