import { Box, Input, Link, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import BackdropItem from 'componets/backdrop/item/item';
import { AuthContext, EntryContext, UserContext } from 'context/User';
import InputDialog from 'dialogs/InputDialog/InputDialog';
import React, { useContext, useEffect } from 'react';
import { update } from 'request/User/User';

export default function EntryCard() {
    const { authUser } = useContext(AuthContext);
    const { dbUser, setDBUser } = useContext(UserContext);
    const { entryUser } = useContext(EntryContext);
    const [error, setError] = React.useState(null);

    const [values, setValues] = React.useState({
        id_entry: 0,
        helpExpanded: false,
        inputExpanded: false,
        clickedSave: false,
    });

    const [dialog, setDialog] = React.useState(false);

    // when the user id is parsed
    useEffect(() => {
        setValues({ ...values, inputExpanded: dbUser.id_entry === 0 });
        setDialog(false);
        setValues({ ...values, clickedSave: false });
    }, [dbUser.id_entry]);

    const parseEntryID = () => (event) => {
        const entryID = (
            event.target.value.match(/\d+\.\d+|\d+\b|\d+(?=\w)/g) || []
        )
            .map(function (v) {
                return +v;
            })
            .shift();
        setValues({ ...values, id_entry: entryID });
    };

    const handleAdd = () => {
        setValues({ ...values, clickedSave: !values.clickedSave });
        if (authUser === null) {
            setDBUser({ ...dbUser, id_entry: values.id_entry });
        } else {
            update(authUser, values, setError);
        }
    };

    const disabled = authUser === null;
    return (
        <Box>
            <BackdropItem
                onClick={() => setDialog(true)}
                icon={<PersonIcon />}
                placeholder={
                    disabled
                        ? 'Please sign in to save your team'
                        : 'Input Team ID'
                }
                placeholderActive={entryUser.name === ''}
                value={entryUser.name}
                disabled={disabled}
                error={error}
            ></BackdropItem>
            <InputDialog
                open={dialog}
                title={dbUser.id_entry === 0 ? 'Add Team ID' : 'Update Team ID'}
                contentText={
                    <Box>
                        {' '}
                        <Typography paragraph>
                            Your FPL Team ID can be found by clicking on the
                            points tab in{' '}
                            <Link href="https://fantasy.premierleague.com">
                                https://fantasy.premierleague.com
                            </Link>
                            , copy and paste the URL to the field below.
                        </Typography>
                        <Typography paragraph>
                            The ID is used to find your team in tournaments &
                            import your mini leagues
                        </Typography>
                    </Box>
                }
                textField={
                    <Input
                        id="standard-adornment-entry-id"
                        color="secondary"
                        error={error !== null}
                        defaultValue={dbUser.id_entry}
                        autoFocus
                        onChange={parseEntryID()}
                        multiline={true}
                        placeholder="https://fantasy.premierleague.com/entry/1612170/event/12"
                    />
                }
                okText={dbUser.id_entry === 0 ? 'Add' : 'Update'}
                disableOkButton={
                    values.id_entry === 0 ||
                    values.id_entry === null ||
                    (values.id_entry !== 0 &&
                        values.id_entry === dbUser.id_entry) ||
                    values.clickedSave
                }
                highlightOkButton
                onClose={() => setDialog(false)}
                onCancelClick={() => setDialog(false)}
                onOkClick={() => handleAdd()}
            />
        </Box>
    );
}
