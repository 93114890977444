import Box from '@material-ui/core/Box';
import { QueryResponseContext } from 'context/query/response';
import { ViewSEProvider } from 'context/view/se';
import ViewLayoutSE from 'layout/view/se/se';
import React, { useContext } from 'react';

export default function ViewLayoutSEList() {
    const { queryResponse } = useContext(QueryResponseContext);

    return (
        <Box>
            {queryResponse.list.map((item) => (
                <ViewSEProvider id={item.object.id} key={item.object.id}>
                    <ViewLayoutSE />
                </ViewSEProvider>
            ))}
        </Box>
    );
}
