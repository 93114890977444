import { Box } from '@material-ui/core';
import BackdropItem from 'componets/backdrop/item/item';
import CreateDialog from 'componets/create/createDialog';
import Managers from 'componets/create/Managers';
import { useError, useGrouping, useManagers } from 'context/create/Provider';
import { FormatListNumbered } from 'mdi-material-ui';
import React, { useState } from 'react';

export const ManagersFilter = () => {
    const [open, setOpen] = useState(false);
    const error = useError();
    const managers = useManagers();
    const grouping = useGrouping();

    const onSave = () => {
        setOpen(false);
    };
    let seedingMethod = '';
    if (managers) {
        if (managers.seeding_method === 'overall_rank') {
            seedingMethod = 'Overall Rank';
        }
        if (managers.seeding_method === 'points') {
            seedingMethod = 'GW Score';
        }
        if (managers.seeding_method === 'total_points') {
            seedingMethod = 'Total Points';
        }
        if (managers.seeding_method === 'rank') {
            seedingMethod = 'League Rank';
        }
        if (managers.seeding_method === 'entry_id') {
            seedingMethod = 'By ID';
        }
        if (managers.seeding_method === 'bank') {
            seedingMethod = 'Bank';
        }
    }
    const maxManagersString =
        managers.max === 10000000 ? '' : ', ' + managers.max + ' Max';
    const disabled = false;
    const activeString = managers
        ? 'Active GW' + managers.active_last_transfer
        : '';
    const value = managers
        ? activeString + ', ' + seedingMethod + maxManagersString
        : '';
    return (
        <Box>
            <BackdropItem
                icon={<FormatListNumbered />}
                placeholder={'Filter & Seed Managers'}
                placeholderActive={false}
                value={value}
                disabled={disabled}
                onClick={() => setOpen(true)}
                error={error}
            />
            <CreateDialog open={open} onClose={() => setOpen(false)}>
                <Managers
                    handleSave={onSave}
                    error={error}
                    active={managers.active}
                    activeLastTransfer={managers.active_last_transfer}
                    seedingMethod={managers.seeding_method}
                    max={managers.max}
                    groupingName={grouping.name}
                />
            </CreateDialog>
        </Box>
    );
};

export default ManagersFilter;
