import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

export default function twitterButton() {
    // constants
    const theme = useTheme();
    const [clicked, setClicked] = useState(false);
    // creating path to our twitter page
    useEffect(() => {
        if (clicked) {
            window.open('https://twitter.com/fplplugin');
            setClicked(false);
        }
    });
    // the business end
    return (
        <Button
            variant="contained"
            style={{
                color: theme.palette.primary.contrastText.dark,
                backgroundColor: theme.palette.primary,
                marginTop: 20,
                fontSize: 12,
            }}
            onClick={() => setClicked(true)}
        >
            Follow us on Twitter
        </Button>
    );
}
