// Firebase
import 'firebase/firestore';

import React, { createContext, useState } from 'react';

export const TournamentContext = createContext(null);
export const TournamentErrorContext = createContext(null);

export const ManagersContext = createContext(null);
export const ManagersErrorContext = createContext(null);

export const DurationContext = createContext(null);
export const DurationErrorContext = createContext(null);

export const GroupingContext = createContext(null);
export const GroupingErrorContext = createContext(null);

export const CreateProvider = ({ children }) => {
    const [tournament, setTournament] = useState({
        name: '',
        id: '',
        response: null,
    });
    const [tournamentError, setTournamentError] = useState(null);
    const [managers, setManagers] = useState({
        active: false,
    });
    const [managersError, setManagersError] = useState(null);
    const [duration, setDuration] = useState(null);
    const [durationError, setDurationError] = useState(null);
    const [grouping, setGrouping] = useState(null);
    const [groupingError, setGroupingError] = useState(null);

    return (
        <TournamentContext.Provider
            value={{
                tournament,
                setTournament,
            }}
        >
            <TournamentErrorContext.Provider
                value={{
                    tournamentError,
                    setTournamentError,
                }}
            >
                <ManagersContext.Provider
                    value={{
                        managers,
                        setManagers,
                    }}
                >
                    <ManagersErrorContext.Provider
                        value={{
                            managersError,
                            setManagersError,
                        }}
                    >
                        <DurationContext.Provider
                            value={{
                                duration,
                                setDuration,
                            }}
                        >
                            <DurationErrorContext.Provider
                                value={{
                                    durationError,
                                    setDurationError,
                                }}
                            >
                                <GroupingContext.Provider
                                    value={{
                                        grouping,
                                        setGrouping,
                                    }}
                                >
                                    <GroupingErrorContext.Provider
                                        value={{
                                            groupingError,
                                            setGroupingError,
                                        }}
                                    >
                                        {children}
                                    </GroupingErrorContext.Provider>
                                </GroupingContext.Provider>
                            </DurationErrorContext.Provider>
                        </DurationContext.Provider>
                    </ManagersErrorContext.Provider>
                </ManagersContext.Provider>
            </TournamentErrorContext.Provider>
        </TournamentContext.Provider>
    );
};
