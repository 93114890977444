import { Card, CardActions, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatSeries from 'componets/create/series';
import Header from 'componets/header/header';
import NumberSelect from 'componets/NumberSelect/NumberSelect';
import {
    ACTIONS,
    stageGroupEnabled,
    useCreate,
    useError,
    useGrouping,
    useStageGroup,
    useStageGroupSeriesFormat,
} from 'context/create/Provider';
import SaveButton from 'layout/SaveButton/SaveButton';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {},
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function RequeststageRR({ handleSave, noSave }) {
    const classes = useStyles();
    const { dispatch } = useCreate();
    const grouping = useGrouping();
    const stage = useStageGroup();
    const error = useError();
    const enabled = stageGroupEnabled();
    const seriesFormat = useStageGroupSeriesFormat();

    const toggleEnabled = () => {
        dispatch({
            type: ACTIONS.SET_STAGE_GROUP_ENABLE_TOGGLE,
        });
    };

    const handleChangeMaxEntriesPerGroup = (event) => {
        dispatch({
            type: ACTIONS.SET_STAGE_GROUP_MAX_ENTRIES_PER_GROUP,
            payload: event.target.value,
        });
    };

    const handleChangeMaxQualifiersPerGroup = (event) => {
        dispatch({
            type: ACTIONS.SET_STAGE_GROUP_MAX_QUALIFIERS_PER_GROUP,
            payload: event.target.value,
        });
    };

    const handleChangeMinNumberOfRepetitions = (event) => {
        dispatch({
            type: ACTIONS.SET_STAGE_GROUP_MIN_NUMBER_OF_REPETITIONS,
            payload: event.target.value,
        });
    };

    const handleChangeGamesMax = (event) => {
        dispatch({
            type: ACTIONS.SET_STAGE_GROUP_SERIES_FORMAT_GAMES,
            payload: event.target.value,
        });
    };

    const maxEntriesPerGroupDisabled = (value) => {
        return grouping.total_ids < value;
    };

    const maxQualifiersPerGroupDisabled = (value) => {
        return stage.object.maxEntriesPerGroup <= value;
    };

    const minNumberOfRepetitionsDisabled = (value) => {
        return (
            (stage.object.maxEntriesPerGroup - 1) *
                value *
                seriesFormat.object.gamesMax >
            37
        );
    };

    const gamesMaxDisabled = (value) => {
        return (
            (stage.object.maxEntriesPerGroup - 1) *
                value *
                seriesFormat.object.gamesMax >
                37 || value > 4
        );
    };

    return (
        <div className={classes.root}>
            <Card>
                <Header
                    title="Group Stage"
                    error={error}
                    toggleExists={true}
                    toggleChecked={enabled}
                    toggleDisabled={false}
                    toggleChange={toggleEnabled}
                    toggleTitle={enabled ? '' : 'Disabled'}
                />
                <CardContent>
                    <NumberSelect
                        inputLabel={'Max Managers per Group'}
                        formHelperText={
                            'Some groups may have less than ' +
                            stage.object.maxEntriesPerGroup.toString() +
                            ' managers'
                        }
                        min={2}
                        max={16}
                        disabled={!enabled}
                        value={stage.object.maxEntriesPerGroup}
                        disabledFunc={maxEntriesPerGroupDisabled}
                        handleChange={handleChangeMaxEntriesPerGroup}
                    />
                </CardContent>
                <CardContent>
                    <NumberSelect
                        inputLabel={'Max Qualifiers per Group'}
                        formHelperText={
                            'The maximium number of managers who can qualifiy from the group'
                        }
                        min={1}
                        disabled={!enabled}
                        max={stage.object.maxEntriesPerGroup}
                        value={stage.object.maxQualifiersPerGroup}
                        handleChange={handleChangeMaxQualifiersPerGroup}
                        disabledFunc={maxQualifiersPerGroupDisabled}
                    />
                </CardContent>
                <CardContent>
                    <NumberSelect
                        inputLabel={'Min Fixture Repeats'}
                        formHelperText={
                            'In the premier league, each team plays each other twice.'
                        }
                        min={1}
                        max={16}
                        disabled={!enabled}
                        value={stage.object.minNumberOfRepetitions}
                        handleChange={handleChangeMinNumberOfRepetitions}
                        disabledFunc={minNumberOfRepetitionsDisabled}
                    />
                </CardContent>
                <CardContent>
                    <FormatSeries
                        handleChangeGamesMax={handleChangeGamesMax}
                        disabled={true}
                        gamesMaxDisabled={gamesMaxDisabled}
                        formatSeries={seriesFormat}
                        disabledText="mutigameweek fixtures disabled due to a known bug"
                    ></FormatSeries>
                </CardContent>
                <CardActions>
                    <SaveButton
                        onClick={handleSave}
                        update={stage.object.id !== ''}
                        noSave={noSave}
                    />
                </CardActions>
            </Card>
        </div>
    );
}
