import { TournamentContext } from 'context/Create';
import { AuthContext } from 'context/User';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    FormatSeriesPost,
    FormatSeriesPut,
} from 'request/Tournament/FormatSeries';
import { HasReference } from 'request/Tournament/utl';

export const FormatSeriesContext = createContext(null);
export const FormatSeriesErrorContext = createContext(null);

export const RequestFormatSeriesProvider = ({ children }) => {
    const { tournament } = useContext(TournamentContext);
    const { authUser } = useContext(AuthContext);

    const [formatSeries, setFormatSeries] = useState({
        object: {
            id: '',
            gamesMin: 1,
            gamesMax: 1,
            totalEntries: 2,
            totalQualifiers: 1,
        },
        hasFormat: {},
        tournamentId: '',
    });

    const [formatSeriesError, setFormatSeriesError] = useState(null);

    useEffect(() => {
        if (tournament.id !== '') {
            setFormatSeries({ ...formatSeries, tournamentId: tournament.id });
        }
    }, [tournament.id]);
    console.log('formatSeries', formatSeries);

    useEffect(() => {
        if (
            formatSeries.tournamentId !== '' &&
            HasReference(formatSeries.hasFormat)
        ) {
            if (formatSeries.object.id === '') {
                FormatSeriesPost(
                    authUser,
                    formatSeries,
                    setFormatSeries,
                    setFormatSeriesError
                );
            } else {
                FormatSeriesPut(
                    authUser,
                    formatSeries,
                    setFormatSeries,
                    setFormatSeriesError
                );
            }
        }
    }, [
        formatSeries.object.gamesMin,
        formatSeries.object.gamesMax,
        formatSeries.object.totalEntries,
        formatSeries.object.totalQualifiers,
        formatSeries.hasFormat,
    ]);

    return (
        <FormatSeriesContext.Provider
            value={{
                formatSeries,
                setFormatSeries,
            }}
        >
            <FormatSeriesErrorContext.Provider
                value={{
                    formatSeriesError,
                    setFormatSeriesError,
                }}
            >
                {children}
            </FormatSeriesErrorContext.Provider>
        </FormatSeriesContext.Provider>
    );
};
