import { ViewTournamentContext } from 'context/view/tournament';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { RoundGetNoAuth } from 'request/Tournament/Round';

export const ViewRoundContext = createContext(null);

export const ViewRoundProvider = (props) => {
    const { children } = props;
    const { tournament } = useContext(ViewTournamentContext);
    const [round, setRound] = useState({
        object: {
            id: props.id,
        },
        base: {
            entryList: [],
            scoreList: [],
            status: {
                value: '',
            },
        },
    });

    const setError = (error) => {
        console.log(error);
    };

    console.log('round context', round);
    useEffect(() => {
        if (round.object.id !== '') {
            RoundGetNoAuth(
                tournament.object.id,
                round.object.id,
                setRound,
                setError
            );
        }
    }, [round.object.id]);

    return (
        <ViewRoundContext.Provider
            value={{
                round,
                setRound,
            }}
        >
            {children}
        </ViewRoundContext.Provider>
    );
};
