import React, { createContext, useEffect, useState } from 'react';
import { TournamentGetNoAuth } from 'request/Tournament/Tournament';

export const ViewTournamentContext = createContext(null);

export const ViewTournamentProvider = ({ children }) => {
    const [tournament, setTournament] = useState({
        object: {
            id: '',
        },
        base: {
            entriesCount: 0,
            entryList: [],
            scoreList: [],
            status: {
                value: '',
            },
        },
    });

    const setError = (error) => {
        console.log(error);
    };

    console.log('tournament context', tournament);
    useEffect(() => {
        if (tournament.object.id !== '') {
            TournamentGetNoAuth(tournament.object.id, setTournament, setError);
        }
    }, [tournament.object.id]);

    return (
        <ViewTournamentContext.Provider
            value={{
                tournament,
                setTournament,
            }}
        >
            {children}
        </ViewTournamentContext.Provider>
    );
};
