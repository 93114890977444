import { Grid } from '@material-ui/core';
import GameContext from 'context/game/gameContext';
import { ViewGameProvider } from 'context/view/game';
import React from 'react';

export default function GameList(props) {
    return (
        <Grid container spacing={1} direction="column">
            {props.queryResponse.list.map((item) => (
                <Grid item key={item.object.id}>
                    <ViewGameProvider id={item.object.id}>
                        <GameContext
                            totalGames={props.queryResponse.list.length}
                            totalQualifiers={props.totalQualifiers}
                        ></GameContext>
                    </ViewGameProvider>
                </Grid>
            ))}
        </Grid>
    );
}
