// React
// Firebase
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/performance';

import { LoginCallToActionButton } from 'componets/login/callToAction';
// validate.js
import { AuthenticatorContext } from 'context/auth/auth';
import React, { useContext } from 'react';

export const LoginCallToAction = () => {
    const { isSignedIn, isPerformingAuthAction, handleSignInClick } =
        useContext(AuthenticatorContext);

    return (
        <LoginCallToActionButton
            isSignedIn={isSignedIn}
            isPerformingAuthAction={isPerformingAuthAction}
            handleSignInClick={handleSignInClick}
        />
    );
};
export default LoginCallToAction;
