import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = () => ({
    circularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
});

class LaunchScreen extends Component {
    render() {
        // Styling
        const { classes } = this.props;

        return <CircularProgress className={classes.circularProgress} />;
    }
}

LaunchScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LaunchScreen);
