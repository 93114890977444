import {
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Header from 'componets/header/header';
import React from 'react';

export default function TextInput({
    title,
    subheader,
    error,
    onChange,
    onClick,
    disabledInput,
    disableSave,
    noSave,
    value,
}) {
    return (
        <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
        >
            <Grid item>
                <Card>
                    <Header
                        title={title}
                        subheader={subheader}
                        error={error}
                        status={false}
                    />
                    <CardContent>
                        <FormControl style={{ width: 300 }}>
                            <InputLabel htmlFor="tournament-name">
                                Tournament Name
                            </InputLabel>
                            <Input
                                onChange={onChange}
                                disabled={disabledInput}
                                value={value}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {!noSave && (
                                            <IconButton
                                                disabled={disableSave}
                                                onClick={onClick}
                                            >
                                                <SaveIcon color="primary" />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                }
                            ></Input>
                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
