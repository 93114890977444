// Firebase
import 'firebase/firestore';

import { ViewTournamentContext } from 'context/view/tournament';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { GroupGetNoAuth } from 'request/Tournament/Group';
import { ScoreGet } from 'request/Tournament/Score';

export const ViewGroupContext = createContext(null);

export const ViewGroupProvider = (props) => {
    const { children } = props;
    const { tournament } = useContext(ViewTournamentContext);
    const [group, setGroup] = useState({
        object: {
            id: props.id,
        },
        base: {
            entryList: [],
            scoreList: [],
            status: {
                value: '',
            },
        },
    });

    const [groupScore, setGroupScore] = useState({
        object: {
            id: '',
            rows: {},
            table: [],
        },
        base: {
            inboundHas: {
                tournamentIds: [],
                stageSeIds: [],
                stageRrIds: [],
                groupIds: [],
                roundIds: [],
                seriesIds: [],
                gameIds: [],
            },
        },
    });

    const setError = (error) => {
        console.log(error);
    };
    console.log('group context', group);
    console.log('group score', groupScore);

    useEffect(() => {
        if (group.base.scoreList.length > 0) {
            ScoreGet(
                tournament.object.id,
                group.base.scoreList[group.base.scoreList.length - 1].id,
                setGroupScore,
                setError
            );
        }
    }, [group.base.scoreList]);

    useEffect(() => {
        if (group.object.id !== '') {
            GroupGetNoAuth(
                tournament.object.id,
                group.object.id,
                setGroup,
                setError
            );
        }
    }, [group.object.id]);

    return (
        <ViewGroupContext.Provider
            value={{
                group,
                setGroup,
                groupScore,
            }}
        >
            {children}
        </ViewGroupContext.Provider>
    );
};
