import { Box, Grid } from '@material-ui/core';
import { QueryResponseContext } from 'context/query/response';
import { ViewGroupProvider } from 'context/view/group';
import ViewLayoutGroup from 'layout/view/group/group';
import React, { useContext } from 'react';

export default function ViewLayoutGroupList(props) {
    const { queryResponse } = useContext(QueryResponseContext);

    return (
        <Box>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                spacing={2}
            >
                {queryResponse.list.map((item) => (
                    <Grid item xs={12} md={5} key={item.object.id}>
                        <ViewGroupProvider id={item.object.id}>
                            <ViewLayoutGroup
                                maxQualifiers={props.maxQualifiers}
                            ></ViewLayoutGroup>
                        </ViewGroupProvider>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
