import { Box, Typography } from '@material-ui/core';
import BackdropItem from 'componets/backdrop/item/item';
import InputDialog from 'dialogs/InputDialog/InputDialog';
import Bookmarks from 'layout/Bookmarks/Bookmarks';
import { Magnify } from 'mdi-material-ui';
import React from 'react';

export const TournamentSelect = () => {
    const [dialog, setDialog] = React.useState(false);
    return (
        <Box>
            <BackdropItem
                icon={<Magnify />}
                placeholder={'Bookmarked Tournaments'}
                placeholderActive={true}
                value={'Name 5'}
                disabled={false}
                onClick={() => setDialog(true)}
            ></BackdropItem>
            <InputDialog
                open={dialog}
                title={'Select a bookmarked Tournament'}
                contentText={
                    <Box>
                        {' '}
                        <Typography paragraph>
                            You must be logged in to bookmark a tournament
                        </Typography>
                        <Typography paragraph>
                            When you view a tournamnet there is a bookmark
                            button at the top of each page. Click it to bookmark
                            the tournament.
                        </Typography>
                    </Box>
                }
                textField={<Bookmarks />}
                okText={''}
                hideOkButton={true}
                disableOkButton={true}
                onClose={() => setDialog(false)}
                onCancelClick={() => setDialog(false)}
                onOkClick={() => setDialog(false)}
            />
        </Box>
    );
};

export default TournamentSelect;
