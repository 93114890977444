import axios from 'axios';

const urlBookmark =
    process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE + '/user/bookmark';

const urlBookmarkWithID = (tournamentID) => {
    return urlBookmark + '/' + tournamentID;
};

export const BookmarkCreate = (
    authUser,
    tournamentID,
    setResponse,
    setError
) => {
    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'post',
            url: urlBookmarkWithID(tournamentID),
            headers: { Authorization: 'Bearer ' + idToken },
        };
        axios(opts)
            .then(function (response) {
                setResponse(response.data);
                setError(null);
            })
            .catch(function (error) {
                setError(error.response);
            });
    });
};

export const BookmarkDelete = (
    authUser,
    tournamentID,
    setResponse,
    setError
) => {
    return authUser
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
            const opts = {
                method: 'delete',
                url: urlBookmarkWithID(tournamentID),
                headers: { Authorization: 'Bearer ' + idToken },
            };
            axios(opts)
                .then(function (response) {
                    setResponse(response.data);
                    setError(null);
                })
                .catch(function (error) {
                    setError(error.response);
                });
        });
};
