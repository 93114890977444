import 'firebase/firestore';

import { Avatar, Box, Card, Grid } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ShareIcon from '@material-ui/icons/Share';
import { DurationContext } from 'context/format/Duration';
import { AuthContext, UserContext } from 'context/User';
import { ViewTournamentContext } from 'context/view/tournament';
import ShareDialog from 'dialogs/ShareDialog/Share.js';
import { Soccer } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';
import { BookmarkCreate, BookmarkDelete } from 'request/User/Bookmark';

export default function ViewLayoutTournament(props) {
    const { tournament } = useContext(ViewTournamentContext);
    const { authUser } = useContext(AuthContext);
    const { dbUser } = useContext(UserContext);

    const { duration } = useContext(DurationContext);
    const [showShare, setShowShare] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);
    const [bookmarkedError, setBookmarkedError] = useState(null);

    if (dbUser.tournaments_bookmarked) {
        let found = false;
        for (let i = 0; i < dbUser.tournaments_bookmarked.length; i++) {
            if (dbUser.tournaments_bookmarked[i] === tournament.object.id) {
                found = true;
            }
        }
        if (bookmarked !== found) {
            setBookmarked(found);
        }
    }

    const setBookmarkedTrue = () => {
        setBookmarked(true);
    };
    const setBookmarkedFalse = () => {
        setBookmarked(false);
    };

    const handleBookmark = () => {
        if (bookmarked) {
            BookmarkDelete(
                authUser,
                tournament.object.id,
                setBookmarkedFalse,
                setBookmarkedError
            );
        } else {
            BookmarkCreate(
                authUser,
                tournament.object.id,
                setBookmarkedTrue,
                setBookmarkedError
            );
        }
    };

    const actions = [];

    if (authUser !== null) {
        actions.push(
            <IconButton
                aria-label="settings"
                key="bookmark"
                onClick={() => handleBookmark()}
            >
                {(bookmarkedError && (
                    <BookmarkBorderIcon color="secondary"></BookmarkBorderIcon>
                )) ||
                    (bookmarked && (
                        <BookmarkIcon color="primary"></BookmarkIcon>
                    )) || (
                        <BookmarkBorderIcon color="primary"></BookmarkBorderIcon>
                    )}
            </IconButton>
        );
    } else {
        actions.push(
            <IconButton
                aria-label="settings"
                key="bookmark"
                onClick={props.openSignInDialog}
            >
                <BookmarkIcon color="primary"></BookmarkIcon>
            </IconButton>
        );
    }

    actions.push(
        <IconButton
            aria-label="settings"
            key="share"
            onClick={() => setShowShare(true)}
        >
            <ShareIcon color="primary" />
        </IconButton>
    );

    return (
        <Box>
            <Grid container justifyContent="space-around">
                <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                    <Card>
                        <CardHeader
                            title={tournament.object.name}
                            avatar={
                                <Avatar
                                    aria-label="recipe"
                                    style={{
                                        backgroundColor: 'rgb(55, 0, 60)',
                                    }}
                                >
                                    <Soccer />
                                </Avatar>
                            }
                            subheader={[
                                <Typography
                                    variant="caption"
                                    key="total_entries"
                                >
                                    Total Entries:{' '}
                                    {tournament.base.entriesCount}
                                </Typography>,
                                <Typography key="start-week" variant="caption">
                                    <br />
                                    Start Week: {duration.start}
                                </Typography>,
                            ]}
                            action={actions}
                        ></CardHeader>
                    </Card>
                </Grid>
            </Grid>
            <ShareDialog
                open={showShare}
                shareTitle={'Share Tournament: ' + tournament.object.name}
                url={'https://fplplugin.com/tour/' + tournament.object.id}
                closeFunc={() => setShowShare(false)}
            ></ShareDialog>
        </Box>
    );
}
