import 'firebase/firestore';

import { Grid, Typography } from '@material-ui/core';
import React from 'react';

export default function GameGridOne() {
    return (
        <Grid container justify="space-between" style={{ paddingLeft: '1%' }}>
            <Grid item xs={1}>
                <Typography variant="caption" align="center">
                    Place
                </Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography variant="caption" noWrap>
                    Manager
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="caption" align="center">
                    Points
                </Typography>
            </Grid>
        </Grid>
    );
}
