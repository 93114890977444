import 'firebase/firestore';

import { Card, CardActionArea, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import GameListContext from 'context/game/listContext';
import { QueryRequestProvider } from 'context/query/request';
import { QueryResponseProvider } from 'context/query/response';
import { ViewSeriesContext } from 'context/view/series';
import { ViewTournamentContext } from 'context/view/tournament';
import ViewLayoutName from 'layout/view/name';
import React, { useContext } from 'react';
import { GameQuery } from 'request/Tournament/Game';

const urlPrefix = 'https://fantasy.premierleague.com/entry';
const urlPostfixHistory = 'history';

export default function ViewLayoutSeries(props) {
    const { tournament } = useContext(ViewTournamentContext);
    const { series } = useContext(ViewSeriesContext);

    const outboundOf = {
        seriesIds: [{ id: series.object.id }],
    };

    if (series === null) {
        return <div />;
    }
    return (
        <div>
            {(series.base.status.value === 'WAITING' && (
                <Card style={{ marginBottom: '20px' }}>
                    {/* <CardHeader
                title={"Series " + series.object.id}
                subheader={<Typography variant="caption">Total Entries: {series.base.entryList.length}</Typography>}
                // subheader={(typeof this.state.stage !== "undefined") && (<Typography variant="caption">Start Week: {this.state.stage.duration.start} | Final Week: {this.state.stage.duration.end}<br />Qualifiers Per Series: {this.state.stage.format.qualifiers}</Typography>)}
                action={<IconButton aria-label="settings"
                //onClick={this.hideAll}
                ><ExpandMoreIcon /></IconButton>} /> */}
                    <Typography
                        variant="caption"
                        style={{ padding: '1px', paddingLeft: '3px' }}
                    >
                        Total Entries: {series.base.entryList.length}
                    </Typography>
                    <Divider></Divider>
                    {series.base.entryList.map((item) => (
                        <CardActionArea
                            key={item.id}
                            onClick={() =>
                                window.open(
                                    `${urlPrefix}/${item.id}/${urlPostfixHistory}`,
                                    '_blank'
                                )
                            }
                        >
                            <Typography
                                variant="body2"
                                style={{ padding: '1px', paddingLeft: '3px' }}
                            >
                                <ViewLayoutName
                                    entry_id={item.id}
                                ></ViewLayoutName>
                            </Typography>
                        </CardActionArea>
                    ))}
                </Card>
            )) || (
                <QueryRequestProvider
                    tournamentID={tournament.object.id}
                    outboundOf={outboundOf}
                    entryIds={[]}
                >
                    <QueryResponseProvider QueryFunc={GameQuery}>
                        <GameListContext
                            gameWeek={props.gameWeek}
                            totalQualifiers={props.totalQualifiers}
                        />
                    </QueryResponseProvider>
                </QueryRequestProvider>
            )}
        </div>
    );
}
