// React
// Firebase
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/performance';

import { Box, IconButton, Menu } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
// validate.js
import { AuthenticatorContext } from 'context/auth/auth';
import { Login } from 'mdi-material-ui';
import React, { Fragment, useContext } from 'react';

export const BarUser = () => {
    const {
        isSignedIn,
        userObject,
        isPerformingAuthAction,
        handleSignOutClick,
        handleSignInClick,
        openMenu,
        setMenu,
        menu,
    } = useContext(AuthenticatorContext);

    return (
        <Box>
            {isSignedIn && (
                <Fragment>
                    <IconButton
                        color="inherit"
                        disabled={isPerformingAuthAction}
                        onClick={openMenu}
                    >
                        {userObject.photoURL ? (
                            <Avatar
                                alt="Avatar"
                                style={{
                                    width: '25px',
                                    height: '25px',
                                }}
                                src={userObject.photoURL}
                            />
                        ) : (
                            <PersonIcon />
                        )}
                    </IconButton>

                    <Menu
                        anchorEl={menu}
                        open={Boolean(menu)}
                        onClose={() => {
                            setMenu(null);
                        }}
                    >
                        <MenuItem
                            disabled={isPerformingAuthAction}
                            onClick={() => {
                                handleSignOutClick();
                            }}
                        >
                            Sign out
                        </MenuItem>
                    </Menu>
                </Fragment>
            )}

            {!isSignedIn && (
                <Fragment>
                    <IconButton
                        color="secondary"
                        disabled={isPerformingAuthAction}
                        variant="outlined"
                        onClick={() => {
                            handleSignInClick();
                        }}
                    >
                        <Login></Login>
                    </IconButton>
                </Fragment>
            )}
        </Box>
    );
};
export default BarUser;
