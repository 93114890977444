import 'firebase/firestore';

import {
    QueryRequestContext,
    QueryRequestProvider,
} from 'context/query/request';
import { QueryResponseProvider } from 'context/query/response';
import { ViewRoundContext } from 'context/view/round';
import { ViewTournamentContext } from 'context/view/tournament';
import ViewLayoutSeriesList from 'layout/view/series/list';
import React, { useContext } from 'react';
import { SeriesQuery } from 'request/Tournament/Series';

export default function ViewLayoutRound(props) {
    const { tournament } = useContext(ViewTournamentContext);
    const { round } = useContext(ViewRoundContext);
    const { queryRequest } = useContext(QueryRequestContext);

    if (props.visible === false) {
        return <div></div>;
    }

    const outboundOf = {
        roundIds: [{ id: round.object.id }],
    };

    if (round === null) {
        return <div />;
    }

    return (
        <div>
            {/* <Card style={{ marginBottom: "20px" }}>
            <CardHeader
                title={"Round " + round.object.id}
                subheader={<Typography variant="caption">Total Entries: {round.base.entryList.length}</Typography>}
                // subheader={(typeof this.state.stage !== "undefined") && (<Typography variant="caption">Start Week: {this.state.stage.duration.start} | Final Week: {this.state.stage.duration.end}<br />Qualifiers Per Round: {this.state.stage.format.qualifiers}</Typography>)}
                avatar={<Avatar aria-label="recipe" style={{ backgroundColor: "rgb(55, 0, 60)" }}>
                    <TableLarge />
                </Avatar>}
                action={<IconButton aria-label="settings"
                //onClick={this.hideAll}
                ><ExpandMoreIcon /></IconButton>} />
        </Card> */}
            <QueryRequestProvider
                tournamentID={tournament.object.id}
                outboundOf={outboundOf}
                entryIds={queryRequest.entryIds}
                gameWeek={props.gameWeek}
            >
                <QueryResponseProvider QueryFunc={SeriesQuery}>
                    <ViewLayoutSeriesList gameWeek={props.gameWeek} />
                </QueryResponseProvider>
            </QueryRequestProvider>
        </div>
    );
}
