import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';

export default function IconLabelButtons(props) {
    return (
        <div>
            {!props.noSave && (
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    className={props.className}
                    onClick={props.onClick}
                    startIcon={<SaveIcon />}
                >
                    {props.update ? 'Update' : 'Save'}
                </Button>
            )}
        </div>
    );
}
