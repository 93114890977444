import { Box, Grid, Typography } from '@material-ui/core';
import Footer from 'content/Footer/Footer';
import { DurationContextProvider } from 'context/format/Duration';
import { QueryRequestProvider } from 'context/query/request';
import { QueryResponseProvider } from 'context/query/response';
import { ViewTournamentContext } from 'context/view/tournament';
import ViewLayoutRRList from 'layout/view/rr/list';
import ViewLayoutSEList from 'layout/view/se/list';
import ViewLayoutTournament from 'layout/view/tournament';
import React, { useContext, useEffect } from 'react';
// v2
import { RoundRobinQuery } from 'request/Tournament/RoundRobin';
import { SingleEliminationQuery } from 'request/Tournament/SingleElimination';

// const useStyles = makeStyles((theme) => ({
//     emptyStateIcon: {
//         fontSize: theme.spacing(12),
//     },
//     user: {
//         width: '100%',
//     },
//     button: {
//         marginTop: theme.spacing(1),
//     },
//     buttonIcon: {
//         marginRight: theme.spacing(1),
//     },
//     grid: {
//         marginTop: '2%',
//         marginBottom: '2%',
//         flexGrow: 1,
//         width: '100%',
//     },
//     expo: {
//         marginTop: '30%',
//     },
//     header: {
//         flexGrow: 1,
//     },
// }));

export default function ViewTournament(props) {
    const { tournament, setTournament } = useContext(ViewTournamentContext);

    const pathname = props.routeProps.location.pathname;
    const pathID = pathname.split('/');
    const id = pathID[pathID.length - 1];

    useEffect(() => {
        setTournament({
            ...tournament,
            object: { ...tournament.object, id: id },
        });
    }, [props.routeProps.location.pathname]);

    return (
        <Footer>
            <DurationContextProvider>
                <Box>
                    <ViewLayoutTournament
                        openSignInDialog={props.openSignInDialog}
                    />

                    <QueryRequestProvider tournamentID={id}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <QueryResponseProvider
                                    QueryFunc={SingleEliminationQuery}
                                >
                                    <ViewLayoutSEList />
                                </QueryResponseProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <QueryResponseProvider
                                    QueryFunc={RoundRobinQuery}
                                >
                                    <ViewLayoutRRList />
                                </QueryResponseProvider>
                            </Grid>
                        </Grid>
                    </QueryRequestProvider>
                </Box>
                <Grid container justifyContent="space-around">
                    <Grid item>
                        <Typography variant="caption">
                            Something does not look correct? email me:
                            owenmonagan@gmail.com or message me on reddit
                            u/ImmOwen with this url. I will fix it immediately!
                        </Typography>
                    </Grid>
                </Grid>
            </DurationContextProvider>
        </Footer>
    );
}
