// Firebase
// import * as firebase from 'firebase';
import 'firebase/firestore';

import app from 'App/Base.js';
import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext(null);

export const UserContext = createContext(null);

export const EntryContext = createContext(null);

const db = app.firestore();

export const AuthProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState(null);

    const [dbUser, setDBUser] = useState({
        id: '',
        id_entry: 0,
        tournaments_bookmarked: [],
        tournaments_created: [],
        groupings_saved: [],
        groupings_bookmarked: [],
        groupings_entry: [],
    });

    const [entryUser, setEntryUser] = useState({
        name: '',
        id: 0,
    });

    useEffect(() => {
        app.auth().onAuthStateChanged((user) => {
            setAuthUser(user);
        });
    }, []);

    // when the db user id changes, update get the updated user and write it to context
    useEffect(() => {
        console.log('authUser change', authUser);
        if (authUser !== null) {
            db.collection('user')
                .doc(authUser.uid)
                .onSnapshot(function (doc) {
                    if (doc.exists) {
                        console.log('Document data:', doc.data());
                        setDBUser(doc.data());
                    } else {
                        console.log('db user does not exist:', doc.data());
                    }
                });
        }
    }, [authUser]);

    // when the saved entry id changes, update the entry object
    useEffect(() => {
        console.log('dbUser change', dbUser);

        if (dbUser.id_entry !== 0 && authUser !== null) {
            db.collection('entry')
                .doc(dbUser.id_entry.toString())
                .onSnapshot(function (doc) {
                    console.log('Document data entry:', doc.data);
                    if (doc.exists) {
                        setEntryUser(doc.data());
                    }
                });
        } else if (dbUser.id_entry !== 0) {
            setEntryUser({
                ...entryUser,
                name: 'Team: ' + dbUser.id_entry.toString(),
            });
        }
    }, [dbUser]);

    return (
        <AuthContext.Provider
            value={{
                authUser,
            }}
        >
            <UserContext.Provider
                value={{
                    dbUser,
                    setDBUser,
                }}
            >
                <EntryContext.Provider
                    value={{
                        entryUser,
                    }}
                >
                    {children}
                </EntryContext.Provider>
            </UserContext.Provider>
        </AuthContext.Provider>
    );
};
