import {
    CardHeader,
    Divider,
    FormControlLabel,
    Switch,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {},
    actions: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        flexGrow: 1,
        width: '100%',
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function Header({
    toggleExists,
    toggleChecked,
    toggleChange,
    toggleDisabled,
    toggleTitle,
    subheader,
    error,
    title,
}) {
    const classes = useStyles();
    const actions = [];

    if (toggleExists === true) {
        actions.push(
            <FormControlLabel
                className={classes.button}
                control={
                    <Switch
                        checked={toggleChecked}
                        onChange={toggleChange}
                        name="headerToggle"
                        color="primary"
                        disabled={toggleDisabled}
                    />
                }
                label={toggleTitle}
            />
        );
    }
    let sub = subheader;
    if (error) {
        sub = (
            <Typography color="error" variant="subtitle2">
                {error.data}
            </Typography>
        );
    }
    return (
        <div className={classes.root}>
            <CardHeader title={title} subheader={sub} action={actions} />
            <Divider />
        </div>
    );
}
