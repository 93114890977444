import axios from 'axios';
import { Get, GetNoAuth } from 'request/Tournament/Base';

import { invalidResponseData } from './utl';

const url = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE + '/tournament';

const urlWithID = (tournamentID) => {
    return url + '/' + tournamentID;
};

export const TournamentPostInit = (
    authUser,
    tournament,
    setTournament,
    grouping,
    setTournamentError
) => {
    const request = {
        name: tournament.name,
        grouping: grouping.id,
    };
    console.log('TournamentPostInit');

    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'post',
            url: url + '/init',
            data: request,
            headers: { Authorization: 'Bearer ' + idToken },
        };

        axios(opts)
            .then(function (response) {
                console.log('init', response);
                const invalidMessage = invalidResponseData(response.data);
                setTournament(response.data);
                setTournamentError(invalidMessage);
            })
            .catch(function (error) {
                console.log('init', error, request);
                setTournamentError(error.response);
            });
    });
};

export const TournamentPostGenerate = (
    authUser,
    tournament,
    setTournament,
    setTournamentError
) => {
    const request = {
        id: tournament.id,
    };

    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'post',
            url: url + '/' + tournament.id + '/generate',
            data: request,
            headers: { Authorization: 'Bearer ' + idToken },
        };

        axios(opts)
            .then(function (response) {
                console.log('tournamentResp', response);
                setTournament(response.data);
                setTournamentError(null);
            })
            .catch(function (error) {
                console.log('compute', error, request);
                setTournamentError(error.response);
            });
    });
};

export const TournamentPostCompute = (
    authUser,
    tournament,
    setTournament,
    setTournamentError
) => {
    const request = {
        id: tournament.object.id,
    };
    if (authUser) {
        authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            const opts = {
                method: 'post',
                url: url + '/' + tournament.object.id + '/compute',
                data: request,
                headers: { Authorization: 'Bearer ' + idToken },
            };

            axios(opts)
                .then(function (response) {
                    console.log('tournamentResp', response);
                    setTournament(response.data);
                    setTournamentError(null);
                })
                .catch(function (error) {
                    console.log('compute', error, request);
                    setTournamentError(error.response);
                });
        });
    }
};

export const TournamentPostUpdate = (id, setTournament, setTournamentError) => {
    const request = {
        id: id,
    };

    const opts = {
        method: 'post',
        url: url + '/' + id + '/update',
        data: request,
    };

    axios(opts)
        .then(function () {
            setTournamentError(null);
        })
        .catch(function (error) {
            console.log('update', error, request);
            setTournamentError(error.response);
        });
};

export const TournamentGet = (authUser, id, setResponse, setError) => {
    return Get(urlWithID(id), authUser, setResponse, setError);
};

export const TournamentGetNoAuth = (id, setResponse, setError) => {
    return GetNoAuth(urlWithID(id), setResponse, setError);
};
