// React
// Firebase
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/performance';

import { Paper } from '@material-ui/core';
// Material-UI
import { MuiThemeProvider } from '@material-ui/core/styles';
import { TabContext } from '@material-ui/lab';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import app from 'App/Base.js';
// Custom
import Bar from 'containers/bar/bar';
import Create from 'content/Create/Create';
import Landing from 'content/Landing/Landing';
import NotFoundContent from 'content/NotFoundContent/NotFoundContent';
import Policy from 'content/Policy/Policy';
import ViewGroup from 'content/View/Group';
import ViewTournament from 'content/View/Tournament';
import { AuthenticatorProvider } from 'context/auth/auth';
import { CreateProvider } from 'context/Create';
import { CreateProvidera } from 'context/create/Provider';
import { GenerateProvider } from 'context/new/generate';
import { RequestStageRRProvider } from 'context/stage/RequestStageRR';
import { RequestStageSEProvider } from 'context/stage/RequestStageSE';
// context
import { AuthProvider } from 'context/User';
import { ViewTournamentProvider } from 'context/view/tournament';
import firebase from 'firebase/app';
import LaunchScreen from 'layout/LaunchScreen/LaunchScreen';
import React, { Fragment, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// validate.js
import settings from 'settings';

import theme from '../themes/fplplugin-light-theme';

ReactGA.initialize('G-QJTPDJQ0VG');
ReactGA.pageview(window.location.pathname + window.location.search);

const auth = app.auth();

auth.useDeviceLanguage();

const App = () => {
    const [currentTab, setCurrentTab] = useState('1');
    const [isAuthReady, setIsAuthReady] = useState(false);
    useEffect(() => {
        // code to run on component mount
        firebase.auth().onAuthStateChanged(() => {
            setIsAuthReady(true);
        });
    }, []);

    return (
        <Router>
            <TabContext value={currentTab}>
                <AuthenticatorProvider>
                    <AuthProvider>
                        <FirebaseAuthProvider
                            firebase={firebase}
                            {...settings.credentials.firebase}
                        >
                            <Paper
                                style={{
                                    backgroundColor:
                                        theme.palette.background.default,
                                    minHeight: 500,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    height: '100%',
                                    flexGrow: 1,
                                }}
                            >
                                <CreateProvider>
                                    <CreateProvidera>
                                        <GenerateProvider>
                                            <RequestStageRRProvider>
                                                <RequestStageSEProvider>
                                                    <MuiThemeProvider
                                                        theme={theme}
                                                    >
                                                        <div
                                                            style={{
                                                                minHeight:
                                                                    '100vh',
                                                                backgroundColor:
                                                                    theme
                                                                        .palette
                                                                        .background
                                                                        .default,
                                                            }}
                                                        >
                                                            {!isAuthReady && (
                                                                <LaunchScreen />
                                                            )}
                                                            {isAuthReady && (
                                                                <Fragment>
                                                                    <Bar
                                                                        title={
                                                                            settings.title
                                                                        }
                                                                        currentTab={
                                                                            currentTab
                                                                        }
                                                                        setCurrentTab={
                                                                            setCurrentTab
                                                                        }
                                                                    >
                                                                        <Switch>
                                                                            <Route
                                                                                path="/"
                                                                                exact
                                                                                render={() => (
                                                                                    <Landing />
                                                                                )}
                                                                            />
                                                                            <Route
                                                                                path="/create"
                                                                                exact
                                                                                render={(
                                                                                    routeProps
                                                                                ) => (
                                                                                    <Create
                                                                                        routeProps={
                                                                                            routeProps
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            />
                                                                            <Route
                                                                                path="/policy"
                                                                                exact
                                                                                render={(
                                                                                    routeProps
                                                                                ) => (
                                                                                    <Policy
                                                                                        routeProps={
                                                                                            routeProps
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            />
                                                                            <Route
                                                                                path="/tour/:id"
                                                                                exact
                                                                                render={(
                                                                                    routeProps
                                                                                ) => (
                                                                                    <ViewTournamentProvider>
                                                                                        <ViewTournament
                                                                                            routeProps={
                                                                                                routeProps
                                                                                            }
                                                                                        />
                                                                                    </ViewTournamentProvider>
                                                                                )}
                                                                            />
                                                                            <Route
                                                                                path="/tour/:id/group/:id"
                                                                                exact
                                                                                render={(
                                                                                    routeProps
                                                                                ) => (
                                                                                    <ViewTournamentProvider>
                                                                                        <ViewGroup
                                                                                            routeProps={
                                                                                                routeProps
                                                                                            }
                                                                                        />
                                                                                    </ViewTournamentProvider>
                                                                                )}
                                                                            />

                                                                            <Route
                                                                                component={
                                                                                    NotFoundContent
                                                                                }
                                                                            />
                                                                        </Switch>
                                                                    </Bar>
                                                                </Fragment>
                                                            )}
                                                        </div>
                                                    </MuiThemeProvider>
                                                </RequestStageSEProvider>
                                            </RequestStageRRProvider>
                                        </GenerateProvider>
                                    </CreateProvidera>
                                </CreateProvider>
                            </Paper>
                        </FirebaseAuthProvider>
                    </AuthProvider>
                </AuthenticatorProvider>
            </TabContext>
        </Router>
    );
};

export default App;
