import Box from '@material-ui/core/Box';
import { QueryResponseContext } from 'context/query/response';
import { ViewRRProvider } from 'context/view/rr';
import ViewLayoutRR from 'layout/view/rr/rr';
import React, { useContext } from 'react';

export default function ViewLayoutRRList() {
    const { queryResponse } = useContext(QueryResponseContext);

    return (
        <Box>
            {queryResponse.list.map((item) => (
                <ViewRRProvider id={item.object.id} key={item.object.id}>
                    <ViewLayoutRR></ViewLayoutRR>
                </ViewRRProvider>
            ))}
        </Box>
    );
}
