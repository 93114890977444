import {
    FormControl,
    Grid,
    Input,
    InputLabel,
    Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberSelect from 'componets/NumberSelect/NumberSelect';
import { ACTIONS, useCreate, useManagers } from 'context/create/Provider';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function VerticalLinearStepper() {
    const classes = useStyles();
    const { dispatch } = useCreate();
    const managers = useManagers();

    const handleChangeMethod = (event) => {
        dispatch({
            type: ACTIONS.SET_MANAGERS_SEEDING_METHOD,
            payload: event.target.value,
        });
    };

    const handleChangeWeek = (event) => {
        dispatch({
            type: ACTIONS.SET_MANAGERS_SEEDING_WEEK,
            payload: event.target.value,
        });
    };

    const disabledWeeks = (value) => {
        return value > 7;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControl className={classes.root}>
                    <InputLabel htmlFor="uncontrolled-native">
                        Seeding:
                    </InputLabel>
                    <Select
                        native
                        value={managers.seeding_method}
                        onChange={handleChangeMethod}
                        input={
                            <Input
                                component={'span'}
                                name="seeded_by"
                                id="uncontrolled-native"
                            />
                        }
                    >
                        <option value="overall_rank">Overall FPL Rank</option>
                        <option value="points">Game Week Score</option>
                        <option value="total_points">Total FPL points</option>
                        <option value="rank">Current League Standings</option>
                        <option value="entry_id">
                            Entry ID (Always includes all managers)
                        </option>
                        <option value="bank">Money in the Bank</option>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <NumberSelect
                    inputLabel={'What game week will seed tournament?'}
                    min={1}
                    max={38}
                    value={managers.seeding_week.toString()}
                    handleChange={handleChangeWeek}
                    disabledFunc={disabledWeeks}
                />
            </Grid>
        </Grid>
    );
}
