import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Footer from 'content/Footer/Footer';
import { DurationContextProvider } from 'context/format/Duration';
import { ViewGroupProvider } from 'context/view/group';
import { ViewTournamentContext } from 'context/view/tournament';
import User from 'layout/user/User';
import ViewLayoutGroup from 'layout/view/group/group';
import ViewLayoutTournament from 'layout/view/tournament';
import React, { useContext, useEffect } from 'react';

// v2

const useStyles = makeStyles((theme) => ({
    emptyStateIcon: {
        fontSize: theme.spacing(12),
    },
    user: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    grid: {
        marginTop: '2%',
        marginBottom: '2%',
        flexGrow: 1,
        width: '100%',
    },
    expo: {
        marginTop: '30%',
    },
    header: {
        flexGrow: 1,
    },
}));

export default function ViewGroup(props) {
    const { tournament, setTournament } = useContext(ViewTournamentContext);

    const classes = useStyles();

    const pathname = props.routeProps.location.pathname;
    const pathID = pathname.split('/');
    const tournamentID = pathID[pathID.length - 3];
    const id = pathID[pathID.length - 1];

    useEffect(() => {
        setTournament({
            ...tournament,
            object: { ...tournament.object, id: tournamentID },
        });
    }, [props.routeProps.location.pathname]);

    if (tournament.object.id === '') {
        return <div></div>;
    }
    return (
        <Footer>
            <DurationContextProvider>
                <Box>
                    <ViewLayoutTournament />
                    <Grid item xs={12} className={classes.grid}>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <User />
                            </Grid>
                        </Grid>
                    </Grid>
                    <ViewGroupProvider id={id}>
                        <ViewLayoutGroup></ViewLayoutGroup>
                    </ViewGroupProvider>
                </Box>
                <Grid container justifyContent="space-around">
                    <Grid item>
                        <Typography variant="caption">
                            Something does not look correct? email me:
                            owenmonagan@gmail.com or message me on reddit
                            u/ImmOwen with this url. I will fix it immediately!
                        </Typography>
                    </Grid>
                </Grid>
            </DurationContextProvider>
        </Footer>
    );
}
