import { Box, Card, Collapse } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import Header from 'componets/header/header';
import InactiveManagers from 'containers/create/Managers/InactiveManagers';
import LimitManagers from 'containers/create/Managers/LimitManagers';
import SeedingMethod from 'containers/create/Managers/SeedingMethod';
import SaveButton from 'layout/SaveButton/SaveButton';
import React, { useState } from 'react';

function getSteps(activeStep, active, activeLastTransfer, seedingMethod, max) {
    let defaultStepNameInactive = 'Filter Inactive Managers';
    if (active && activeStep > 0) {
        defaultStepNameInactive =
            'Filtering ' +
            activeLastTransfer.toString() +
            ' Week Inactive Managers';
    } else if (!active && activeStep > 0) {
        defaultStepNameInactive = 'Not Filtering Inactive Managers';
    }

    let defaultStepNameSeeding = 'Choose Seeding Method';
    if (activeStep > 1 && seedingMethod !== '') {
        defaultStepNameSeeding = 'Managers Seeded by ' + seedingMethod;
    }

    let defaultStepNameLimit = 'Limit the Total Managers';
    if (activeStep > 2) {
        defaultStepNameLimit = 'Limited to ' + max.toString() + ' Managers';
    }

    return [
        defaultStepNameInactive,
        defaultStepNameSeeding,
        defaultStepNameLimit,
    ];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return <InactiveManagers />;
        case 1:
            return <SeedingMethod />;
        case 2:
            return <LimitManagers />;
        default:
            return 'Unknown step';
    }
}

export default function Managers({
    error,
    handleSave,
    active,
    activeLastTransfer,
    seedingMethod,
    max,
    groupingName,
    noSave,
}) {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const steps = getSteps(
        activeStep,
        active,
        activeLastTransfer,
        seedingMethod,
        max
    );

    return (
        <Card>
            <Header
                title="Mangers"
                subheader={groupingName}
                error={error}
                onClick={() => {}}
            />
            <Collapse in={true} timeout="auto" unmountOnExit>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <Box>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        Back
                                    </Button>
                                    {activeStep !== steps.length - 1 && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </Button>
                                    )}
                                    {activeStep === steps.length - 1 &&
                                        !noSave && (
                                            <SaveButton onClick={handleSave} />
                                        )}
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0}>
                        <Typography>
                            All steps completed - you&apos;re finished{' '}
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </Paper>
                )}
            </Collapse>
        </Card>
    );
}
