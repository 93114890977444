import 'firebase/firestore';

import { Avatar, Card, CardContent, Collapse } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
    QueryRequestContext,
    QueryRequestProvider,
} from 'context/query/request';
import { QueryResponseProvider } from 'context/query/response';
import { ViewRRContext } from 'context/view/rr';
import { ViewTournamentContext } from 'context/view/tournament';
import ViewLayoutGroupList from 'layout/view/group/list';
import { TableLarge } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';
import { GroupQuery } from 'request/Tournament/Group';

export default function ViewLayoutRR() {
    const { tournament } = useContext(ViewTournamentContext);
    const { rr } = useContext(ViewRRContext);
    const { queryRequest } = useContext(QueryRequestContext);

    const [visible] = useState(true);

    const outboundOf = {
        stageRrIds: [{ id: rr.object.id }],
    };
    if (rr === null) {
        return <div />;
    }

    return (
        <div>
            <Card style={{ marginBottom: '20px' }}>
                <CardHeader
                    title={'Group Stage'}
                    subheader={[
                        <Typography variant="caption" key="total_entries">
                            Total Entries: {rr.base.entriesCount}
                        </Typography>,
                        <Typography variant="caption" key="max_managers">
                            <br />
                            Max Managers Per Group:{' '}
                            {rr.object.maxEntriesPerGroup}
                        </Typography>,
                        <Typography variant="caption" key="max_qualifiers">
                            <br />
                            Max Qualifiers Per Group:{' '}
                            {rr.object.maxQualifiersPerGroup}
                        </Typography>,
                        <Typography variant="caption" key="repeats">
                            <br />
                            Teams Play Each Other:{' '}
                            {rr.object.minNumberOfRepetitions} Time
                        </Typography>,
                    ]}
                    // subheader={(typeof this.state.stage !== "undefined") && (<Typography variant="caption">Start Week: {this.state.stage.duration.start} | Final Week: {this.state.stage.duration.end}<br />Qualifiers Per Group: {this.state.stage.format.qualifiers}</Typography>)}
                    avatar={
                        <Avatar
                            aria-label="recipe"
                            style={{ backgroundColor: 'rgb(55, 0, 60)' }}
                        >
                            <TableLarge />
                        </Avatar>
                    }
                    // action={<IconButton aria-label="settings"
                    //     onClick={() => setVisble(!visible)}
                    // ><ExpandMoreIcon /></IconButton>}
                />
                <CardContent>
                    <Grid container justifyContent="space-around"></Grid>
                </CardContent>
            </Card>
            {/* <Grid container justifyContent="space-evenly">
            {(this.state.visible) && (currentGroups)}
        </Grid> */}
            <QueryRequestProvider
                tournamentID={tournament.object.id}
                outboundOf={outboundOf}
                entryIds={queryRequest.entryIds}
            >
                <QueryResponseProvider QueryFunc={GroupQuery}>
                    <Collapse in={visible} timeout="auto" unmountOnExit>
                        <ViewLayoutGroupList
                            maxQualifiers={rr.object.maxQualifiersPerGroup}
                        />
                    </Collapse>
                </QueryResponseProvider>
            </QueryRequestProvider>
        </div>
    );
}
