const urlBase =
    process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE + '/tournament';

export const HasReference = (references) => {
    console.log('references', references, typeof references);

    if (typeof references !== 'object') {
        return false;
    }
    // ensure there is an id in the references
    for (const [value] of Object.entries(references)) {
        if (value.length > 0) {
            for (let i = 0; i < value.length; i++) {
                if (value[i].id !== '' && value[i].id !== null) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const urlWithTournament = (postfix, tournamentID) => {
    return urlBase + '/' + tournamentID + '/' + postfix;
};

export const urlWithID = (postfix, tournamentID, id) => {
    return urlBase + '/' + tournamentID + '/' + postfix + '/' + id;
};

export const invalidResponseData = (response) => {
    if (response.data === '') {
        return 'empty response';
    }
    return null;
};
