import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import FormatSeries from 'componets/create/series';
import Header from 'componets/header/header';
import NumberSelect from 'componets/NumberSelect/NumberSelect';
import {
    ACTIONS,
    stageKnockoutEnabled,
    useCreate,
    useError,
    useStageKnockout,
    useStageKnockoutSeriesFormat,
} from 'context/create/Provider';
import SaveButton from 'layout/SaveButton/SaveButton';
import React from 'react';

export default function SingleElimination({ handleSave, noSave }) {
    const { dispatch } = useCreate();
    const error = useError();
    const stage = useStageKnockout();
    const seriesFormat = useStageKnockoutSeriesFormat();
    const enabled = stageKnockoutEnabled();

    const toggleEnabled = () => {
        dispatch({
            type: ACTIONS.SET_STAGE_KNOCKOUT_ENABLE_TOGGLE,
        });
    };

    const handleChangeMaxQualifyingRoundPosition = () => {};

    const maxQualifyingRoundPositionDisabled = (value) => {
        return (stage.object.maxQualifyingRoundPosition - 1) * value > 38;
    };

    const handleChangeGamesMax = (event) => {
        dispatch({
            type: ACTIONS.SET_STAGE_KNOCKOUT_SERIES_FORMAT_GAMES,
            payload: event.target.value,
        });
    };

    const gamesMaxDisabled = (value) => {
        return value > 4;
    };

    return (
        <Card>
            <Header
                title="Knockout Stage"
                error={error}
                toggleExists={true}
                toggleChecked={enabled}
                toggleDisabled={false}
                toggleChange={toggleEnabled}
                toggleTitle={enabled ? '' : 'Disabled'}
            />
            <CardContent>
                <Typography variant="caption" color="secondary">
                    Knockout stages can qualify into other stages comming soon!
                </Typography>
                <NumberSelect
                    inputLabel={'Qualify when reaching round'}
                    formHelperText={
                        'Round 1 is the final, Round 2 is semi final....'
                    }
                    min={1}
                    max={16}
                    value={stage.object.maxQualifyingRoundPosition}
                    disabledFunc={maxQualifyingRoundPositionDisabled}
                    handleChange={handleChangeMaxQualifyingRoundPosition}
                    disabled={true}
                />
            </CardContent>
            <CardContent>
                <FormatSeries
                    handleChangeGamesMax={handleChangeGamesMax}
                    gamesMaxDisabled={gamesMaxDisabled}
                    formatSeries={seriesFormat}
                    disabled={true}
                    disabledText="mutigameweek fixtures disabled due to a known bug"
                ></FormatSeries>
            </CardContent>
            <CardActions>
                <SaveButton
                    onClick={handleSave}
                    update={stage.object.id !== ''}
                    noSave={noSave}
                />
            </CardActions>
        </Card>
    );
}
