import React, { createContext, useEffect, useState } from 'react';

export const GenerateContext = createContext(null);

export const GenerateProvider = ({ children }) => {
    const [generate, setGenerate] = useState({
        se: {
            editing: true,
            error: null,
            series: {
                status: '',
                error: null,
            },
        },
        rr: {
            editing: true,
            error: null,
            series: {
                editing: true,
                error: null,
            },
            scoregroup: {
                status: '',
                error: null,
            },
        },
        tournament: {
            editing: false,
            error: null,
            scoregame: {
                editing: false,
                error: null,
            },
        },
        managers: {
            editing: true,
            error: null,
        },
        duration: {
            editing: true,
            error: null,
        },
        valid: false,
        error: null,
    });

    useEffect(() => {
        if (generate.valid !== isValid(generate)) {
            setGenerate({ ...generate, valid: !generate.valid });
        }
    }, [generate]);

    return (
        <GenerateContext.Provider
            value={{
                generate,
                setGenerate,
            }}
        >
            {children}
        </GenerateContext.Provider>
    );
};

const isValid = (generate) => {
    // ensure there is an id in the references

    return (
        !generate.managers.editing &&
        generate.managers.error === null &&
        !generate.se.editing &&
        generate.se.error === null &&
        !generate.rr.editing &&
        generate.rr.error === null &&
        !generate.duration.editing &&
        generate.duration.error === null
    );
};
