import { Grid, makeStyles } from '@material-ui/core';
import Managers from 'componets/create/Managers';
import Duration from 'containers/create/Duration';
import Grouping from 'containers/create/Grouping';
import RoundRobin from 'containers/create/RoundRobin';
import SingleElimination from 'containers/create/SingleElimination';
import Generate from 'containers/create/Status';
import TournamentName from 'containers/create/TournamentName';
import Footer from 'content/Footer/Footer';
import { AuthContext } from 'context/User';
import React, { useContext } from 'react';
import { Redirect } from 'react-router';

const useStyles = makeStyles((theme) => ({
    emptyStateIcon: {
        fontSize: theme.spacing(12),
    },
    user: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    grid: {
        marginTop: '2%',
        marginBottom: '2%',
        padding: '1%',
        flexGrow: 1,
        width: '100%',
    },
    expo: {
        marginTop: '30%',
    },
    header: {
        flexGrow: 1,
    },
}));

export default function Create() {
    const { authUser } = useContext(AuthContext);

    const classes = useStyles();

    if (authUser === null) {
        const url = '/';
        return <Redirect to={url} />;
    }

    return (
        <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            className={classes.grid}
        >
            <Footer>
                <Grid item xs={12} className={classes.grid}>
                    <Grid
                        container
                        justifyContent="space-around"
                        alignContent="center"
                        alignItems="flex-start"
                        className={classes.grid}
                    >
                        <Grid item xs={12} className={classes.grid}>
                            <Generate />
                        </Grid>

                        <Grid item xs={12} sm={6} className={classes.grid}>
                            <Grouping />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.grid}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignContent="space-between"
                                alignItems="flex-start"
                            >
                                <Grid item xs={12} className={classes.grid}>
                                    <TournamentName noSave={true} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    className={classes.grid}
                                >
                                    <Managers noSave={true} />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    className={classes.grid}
                                >
                                    <Duration noSave={true}></Duration>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    className={classes.grid}
                                >
                                    <RoundRobin noSave={true}></RoundRobin>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    className={classes.grid}
                                >
                                    <SingleElimination
                                        noSave={true}
                                    ></SingleElimination>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Footer>
        </Grid>
    );
}
