import TextInput from 'componets/Input/TextInput';
import {
    ACTIONS,
    useCreate,
    useError,
    useTournament,
} from 'context/create/Provider';
import React from 'react';

export const TournamentName = ({ handleSave, noSave }) => {
    const error = useError();
    const { dispatch } = useCreate();
    const tournament = useTournament();

    const handleTournamentNameChange = () => (event) => {
        dispatch({
            type: ACTIONS.SET_TOURNAMENT_NAME,
            payload: event.target.value,
        });
    };

    return (
        <TextInput
            title={'Name your tournament'}
            subheader=""
            error={error}
            onChange={handleTournamentNameChange()}
            onClick={handleSave}
            disabledInput={false}
            disableSave={false}
            noSave={noSave}
            value={tournament.name}
        />
    );
};

export default TournamentName;
