import { Button, Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    RedditIcon,
    RedditShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';

const styles = (theme) => ({
    tabs: {
        marginBottom: theme.spacing(1),
    },
});

class ShareDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copyText: 'Copy Link',
            copied: false,
        };
    }

    render() {
        // Styling
        const { open, title, url } = this.props;
        const subject = 'This tournament uses Fantasy Premier League teams';
        const hashtags = ['fplugin', 'fplplugin', 'fpl'];
        const iconSize = 32;
        let buttonColor = 'secondary';
        if (this.state.copied) {
            buttonColor = 'inherit';
        }
        return (
            <Dialog open={open}>
                <DialogTitle>{this.props.shareTitle}</DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item>
                            <WhatsappShareButton
                                title={title}
                                separator=" "
                                url={url}
                            >
                                <WhatsappIcon size={iconSize} round={true} />
                            </WhatsappShareButton>
                        </Grid>
                        <Grid item>
                            <FacebookShareButton
                                quote={title}
                                separator=" "
                                hashtags={hashtags}
                                url={url}
                            >
                                <FacebookIcon size={iconSize} round={true} />
                            </FacebookShareButton>
                        </Grid>
                        <Grid item>
                            <RedditShareButton
                                title={title}
                                separator=" "
                                url={url}
                            >
                                <RedditIcon size={iconSize} round={true} />
                            </RedditShareButton>
                        </Grid>
                        <Grid item>
                            <TwitterShareButton
                                title={title}
                                separator=" "
                                hashtags={hashtags}
                                url={url}
                            >
                                <TwitterIcon size={iconSize} round={true} />
                            </TwitterShareButton>
                        </Grid>
                        <Grid item>
                            <EmailShareButton
                                subject={title}
                                separator=" "
                                body={subject}
                                url={url}
                            >
                                <EmailIcon size={iconSize} round={true} />
                            </EmailShareButton>
                        </Grid>
                        <Grid item>
                            <ViberShareButton
                                title={title}
                                separator=" "
                                url={url}
                            >
                                <ViberIcon size={iconSize} round={true} />
                            </ViberShareButton>
                        </Grid>
                        <Grid item>
                            <CopyToClipboard
                                text={this.props.url}
                                onCopy={() =>
                                    this.setState({
                                        copyText: 'Copied Link',
                                        copied: true,
                                    })
                                }
                            >
                                <Button
                                    variant="outlined"
                                    color={buttonColor}
                                    size="small"
                                >
                                    <Typography variant="caption">
                                        {this.state.copyText}
                                    </Typography>
                                </Button>
                            </CopyToClipboard>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Button color="primary" onClick={this.props.closeFunc}>
                    Close
                </Button>
            </Dialog>
        );
    }
}

ShareDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    shareTitle: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    closeFunc: PropTypes.func.isRequired,
};

export default withStyles(styles)(ShareDialog);
