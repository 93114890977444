import Dialog from '@material-ui/core/Dialog';
import React from 'react';

export const CreateDialog = ({ children, open, onClose }) => {
    // Dialog Properties

    return (
        <Dialog open={open} onClose={onClose}>
            {children}
        </Dialog>
    );
};

export default CreateDialog;
