import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createTheme({
    palette: {
        primary: {
            light: '#8d3f75',
            main: '#5d1049',
            dark: '#310021',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#ffdd4b',
            main: '#ffab00',
            dark: '#c67c00',
            contrastText: '#ffffff',
        },
        action: {},
        text: {},
    },
    status: {
        danger: 'orange',
        error: 'red',
    },
    typography: {
        // fontFamily: 'Roboto',
        // fontSize: 14,
        // fontWeightLight: 300,
        // fontWeightRegular: 420,
        // fontWeightMedium: 500,
        // fontWeightBold: 700,
    },
    shape: {
        borderRadius: 15,
    },
});

theme.iconSpin = {
    animation: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.longest,
        }),
    },
    r360: {
        transform: 'rotate(360deg)',
    },
    r180: {
        transform: 'rotate(180deg)',
    },
};

theme = responsiveFontSizes(theme);

export default theme;
