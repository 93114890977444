// Firebase
import 'firebase/firestore';

import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    ACTIONS,
    useCreate,
    useGrouping,
    useManagers,
} from 'context/create/Provider';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function Inactive() {
    const { dispatch } = useCreate();
    const managers = useManagers();
    const grouping = useGrouping();
    const classes = useStyles();
    const handleChangeEnable = () => {
        dispatch({
            type: ACTIONS.SET_MANAGERS_ACTIVE,
            payload: !managers.active,
        });
    };
    const handleChangeWeek = (event) => {
        dispatch({
            type: ACTIONS.SET_MANAGERS_ACTIVE_LAST_TRANSFER,
            payload: event.target.value,
        });
    };

    const items = [<MenuItem key={-1} value="undefined"></MenuItem>];
    // undefined when the season has not started
    const hasLastTransferTotals = grouping.last_transfer_totals !== null;

    if (hasLastTransferTotals) {
        let total = 0;
        for (const [key, value] of Object.entries(
            grouping.last_transfer_totals
        ).reverse()) {
            total += value;
            items.push(
                <MenuItem key={key} value={parseInt(key)}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography>Game Week {key}</Typography>
                        </Grid>{' '}
                        <Grid item>
                            <FormHelperText>
                                {total} managers active
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </MenuItem>
            );
        }
    }
    return (
        <Box className={classes.root}>
            <FormControlLabel
                className={classes.button}
                control={
                    <Switch
                        checked={managers.active}
                        onChange={handleChangeEnable}
                        name="checkedB"
                        color="primary"
                        disabled={!hasLastTransferTotals}
                    />
                }
                label="Filter Inactive Managers"
            />
            <FormControl className={classes.root}>
                <InputLabel id="demo-simple-select-helper-label">
                    Active Since:
                </InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    component={'span'}
                    disabled={!managers.active}
                    color="primary"
                    value={managers.active_last_transfer}
                    onChange={handleChangeWeek}
                >
                    {items}
                </Select>
                <FormHelperText id="active-managers-info" component={'span'}>
                    Active managers have made a transfer or used a chip
                </FormHelperText>
            </FormControl>
        </Box>
    );
}
