import { QueryRequestContext } from 'context/query/request';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const QueryResponseContext = createContext(null);

export const QueryResponseProvider = (props) => {
    const { queryRequest } = useContext(QueryRequestContext);
    const { children } = props;

    const [queryResponse, setQueryResponse] = useState({
        hasMore: 0,
        list: [],
    });

    const setError = (error) => {
        console.log(error);
    };
    console.log('queryResponse context', queryResponse);
    useEffect(() => {
        if (props.QueryFunc !== null) {
            props.QueryFunc(queryRequest, setQueryResponse, setError);
        }
    }, [queryRequest]);
    return (
        <QueryResponseContext.Provider
            value={{
                queryResponse,
                setQueryResponse,
            }}
        >
            {children}
        </QueryResponseContext.Provider>
    );
};
