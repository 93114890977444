// Firebase
import 'firebase/firestore';

import app from 'App/Base.js';
import React, { useState } from 'react';

const db = app.firestore();

export default function ViewName(props) {
    const [entry, setEntry] = useState({
        name: props.entry_id,
    });

    db.collection('entry')
        .doc(props.entry_id)
        .onSnapshot(function (doc) {
            if (doc.exists) {
                setEntry(doc.data());
            }
        });

    return <div>{entry.name}</div>;
}
