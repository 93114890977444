import React, { createContext, useState } from 'react';

export const QueryRequestContext = createContext(null);

export const QueryRequestProvider = (props) => {
    const { children, tournamentID, outboundOf, statuses, entryIds } = props;
    const [queryRequest, setQueryRequest] = useState({
        // batchNumber: 0,
        entryIds: entryIds,
        // inboundOf: {
        //     gameIds: [{ id: "" }],
        //     groupIds: [{ id: "" }],
        //     roundIds: [{ id: "" }],
        //     seriesIds: [{ id: "" }],
        //     stageRrIds: [{ id: "" }],
        //     stageSeIds: [{ id: "" }],
        //     tournamentIds: [{ id: "" }],
        // },
        outboundOf: outboundOf,
        // outboundOf: {
        //     gameIds: [{ id: "" }],
        //     groupIds: [{ id: "" }],
        //     roundIds: [{ id: "" }],
        //     seriesIds: [{ id: "" }],
        //     stageRrIds: [{ id: "" }],
        //     stageSeIds: [{ id: "" }],
        //     tournamentIds: [{ id: "" }],
        // },
        statuses: statuses,
        tournamentId: tournamentID,
    });
    // const setError = (error) => {
    //     console.log(error)
    // };

    // console.log("queryRequest context", queryRequest);
    // useEffect(() => {
    //     if (tournament.object.id !== "") {
    //         TournamentGetNoAuth(tournament.object.id, setTournament, setError)
    //     }
    // }, [tournament.object.id]);

    return (
        <QueryRequestContext.Provider
            value={{
                queryRequest,
                setQueryRequest,
            }}
        >
            {children}
        </QueryRequestContext.Provider>
    );
};
