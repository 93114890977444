import { ViewTournamentContext } from 'context/view/tournament';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { RoundRobinGetNoAuth } from 'request/Tournament/RoundRobin';

export const ViewRRContext = createContext(null);

export const ViewRRProvider = (props) => {
    const { children } = props;
    const { tournament } = useContext(ViewTournamentContext);
    const [rr, setRR] = useState({
        object: {
            id: props.id,
            maxEntriesPerGroup: 0,
            maxQualifiersPerGroup: 0,
            minNumberOfRepetitions: 0,
        },
        base: {
            entryList: [],
            scoreList: [],
            status: {
                value: '',
            },
            sequence: [
                {
                    stage: 0,
                },
            ],
        },
    });

    const setError = (error) => {
        console.log(error);
    };

    console.log('rr context', rr);
    useEffect(() => {
        if (rr.object.id !== '') {
            RoundRobinGetNoAuth(
                tournament.object.id,
                rr.object.id,
                setRR,
                setError
            );
        }
    }, [rr.object.id]);

    return (
        <ViewRRContext.Provider
            value={{
                rr,
                setRR,
            }}
        >
            {children}
        </ViewRRContext.Provider>
    );
};
