import {
    ButtonBase,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HelpIcon from '@material-ui/icons/Help';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import { AuthContext, EntryContext, UserContext } from 'context/User';
import React, { useContext, useEffect } from 'react';
import { update } from 'request/User/User';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        minWidth: 350,
    },
    expand: theme.iconSpin.animation,
    expandOpen: theme.iconSpin.r180,
    save: theme.iconSpin.animation,
    saveValue: theme.iconSpin.r360,
    inputText: {
        flexGrow: 1,
    },
}));

export default function EntryCard() {
    const { authUser } = useContext(AuthContext);
    const { dbUser, setDBUser } = useContext(UserContext);
    const { entryUser } = useContext(EntryContext);
    const [error, setError] = React.useState(null);

    const classes = useStyles();

    const [values, setValues] = React.useState({
        id_entry: 0,
        helpExpanded: false,
        inputExpanded: false,
        clickedSave: false,
    });

    // when the user id is parsed
    useEffect(() => {
        setValues({ ...values, inputExpanded: dbUser.id_entry === 0 });
    }, [dbUser.id_entry]);

    // useEffect(() => {
    //     if (authUser !== null && values.id_entry > 0) {
    //         update(authUser, values, setError);
    //     }
    // }, [authUser]);

    const parseEntryID = () => (event) => {
        const entryID = (
            event.target.value.match(/\d+\.\d+|\d+\b|\d+(?=\w)/g) || []
        )
            .map(function (v) {
                return +v;
            })
            .shift();
        setValues({ ...values, id_entry: entryID });
    };

    const handleSaveClick = () => {
        setValues({ ...values, clickedSave: !values.clickedSave });
        if (authUser === null) {
            setDBUser({ ...dbUser, id_entry: values.id_entry });
        } else {
            update(authUser, values, setError);
        }
    };

    const handleCloseInputClick = () => {
        setValues({ ...values, inputExpanded: !values.inputExpanded });
    };

    const handleHelpClick = () => {
        setValues({ ...values, helpExpanded: !values.helpExpanded });
    };

    const handleInputClick = () => {
        setValues({ ...values, inputExpanded: !values.inputExpanded });
    };

    const openInNewTab = () => {
        const win = window.open(
            'https://fantasy.premierleague.com/entry/' +
                entryUser.id.toString() +
                '/history',
            '_blank'
        );
        win.focus();
    };

    return (
        <Card className={classes.root}>
            <Collapse in={!values.inputExpanded} timeout="auto" unmountOnExit>
                <CardHeader
                    title={
                        <Typography variant="h5">{entryUser.name}</Typography>
                    }
                    subheader={
                        <Typography variant="caption" color="textSecondary">
                            <ButtonBase onClick={openInNewTab}>
                                Imported Manager
                            </ButtonBase>
                        </Typography>
                    }
                    action={
                        <IconButton
                            aria-label="toggle help"
                            onClick={handleInputClick}
                            aria-expanded={values.inputExpanded}
                        >
                            {<EditOutlinedIcon color="primary" />}
                        </IconButton>
                    }
                />
            </Collapse>

            <Collapse in={values.inputExpanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <FormControl
                        fullWidth
                        className={clsx(classes.inputText, classes.textField)}
                    >
                        <InputLabel
                            color="secondary"
                            htmlFor="standard-adornment-entry-id"
                        >
                            <Typography color="secondary">
                                Enter Your Team ID
                            </Typography>
                        </InputLabel>
                        <Input
                            id="standard-adornment-entry-id"
                            color="secondary"
                            error={error !== null}
                            autoFocus
                            disableUnderline={error === null}
                            onChange={parseEntryID()}
                            placeholder="https://fantasy.premierleague.com/entry/1612170/event/12"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-expanded={values.inputExpanded}
                                        disabled={values.id_entry === 0}
                                        onClick={handleSaveClick}
                                        color={'secondary'}
                                        className={clsx(classes.save, {
                                            [classes.saveValue]:
                                                values.clickedSave,
                                        })}
                                        aria-label="toggle password visibility"
                                    >
                                        {values.id_entry === 0 ? (
                                            <SaveIcon />
                                        ) : (
                                            <SaveIcon />
                                        )}
                                    </IconButton>
                                    <Collapse
                                        in={dbUser.id_entry !== 0}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <IconButton
                                            aria-expanded={values.inputExpanded}
                                            disabled={
                                                values.id_entry === 0 &&
                                                dbUser.id_entry === 0
                                            }
                                            onClick={handleCloseInputClick}
                                            color={'secondary'}
                                            className={clsx(classes.save, {
                                                [classes.saveValue]:
                                                    values.inputExpanded,
                                            })}
                                            aria-label="cancel update visibility"
                                        >
                                            <CloseIcon color="primary" />
                                        </IconButton>
                                    </Collapse>
                                    <IconButton
                                        aria-label="toggle help"
                                        onClick={handleHelpClick}
                                        aria-expanded={values.helpExpanded}
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]:
                                                values.helpExpanded,
                                        })}
                                    >
                                        {<HelpIcon color="primary" />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </CardContent>
                <Collapse in={values.helpExpanded} timeout="auto" unmountOnExit>
                    <Divider />
                    <CardContent>
                        <Typography paragraph>
                            Your FPL Team ID can be found by clicking on the
                            points tab in{' '}
                            <Link href="https://fantasy.premierleague.com">
                                https://fantasy.premierleague.com
                            </Link>
                            , copy and paste the URL to the above field.
                        </Typography>
                        <Typography paragraph>
                            The ID is used to find your team in tournaments
                        </Typography>
                    </CardContent>
                </Collapse>
            </Collapse>
        </Card>
    );
}
