import { Grid, Typography } from '@material-ui/core';
import React from 'react';

export const TabLabel = ({ title, icon, active }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
                borderWidth: active ? 3 : 0,
                borderColor: '#fff',
                borderStyle: 'solid',
                borderRadius: 20,
            }}
        >
            <Grid item>{icon}</Grid>
            <Grid item>
                <Typography
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                    }}
                    variant="overline"
                >
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default TabLabel;
