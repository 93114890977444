import { GetNoAuth } from 'request/Tournament/Base';
import { urlWithID } from 'request/Tournament/utl';

const postfix = 'score';

export const ScoreGet = (tournamentid, id, setResponse, setError) => {
    return GetNoAuth(
        urlWithID(postfix, tournamentid, id),
        setResponse,
        setError
    );
};
