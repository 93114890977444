import { Box, Grid, Toolbar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import TabPanel from '@material-ui/lab/TabPanel';
import { Logo } from 'componets/logo/logo';
import GroupingSelect from 'containers/bar/create/groupingSelect';
import GroupStage from 'containers/bar/create/groupStage';
import KnockoutStage from 'containers/bar/create/knockoutStage';
import ManagersFilter from 'containers/bar/create/managersFilter';
import { TournamentNameItem } from 'containers/bar/create/tournamentName';
import Manager from 'containers/bar/manager';
import BarTabs from 'containers/bar/tabs';
import BarUser from 'containers/bar/user';
import TournamentSelect from 'containers/bar/view/tournamentSelect';
import React from 'react';

import DurationItem from './create/duration';

const styles = (theme) => ({
    signUpButton: {
        flexGrow: 1,
        marginRight: theme.spacing(1),
    },
    local: {
        cursor: 'pointer',
    },
});

export const Bar = (props) => {
    return (
        <Box>
            <AppBar
                position="static"
                elevation={1}
                style={{ paddingBottom: 10 }}
            >
                <Toolbar variant="regular">
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Logo></Logo>
                                </Grid>
                                <Grid item>
                                    <BarTabs {...props}></BarTabs>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <BarUser />
                        </Grid>
                    </Grid>
                </Toolbar>
                <TabPanel value="1">
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <Manager />
                        </Grid>
                        <Grid item>
                            <TournamentSelect />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="2">
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <Manager />
                        </Grid>
                        <Grid item>
                            <GroupingSelect />
                        </Grid>
                        <Grid item>
                            <TournamentNameItem />
                        </Grid>
                        <Grid item>
                            <ManagersFilter />
                        </Grid>
                        <Grid item>
                            <GroupStage />
                        </Grid>
                        <Grid item>
                            <KnockoutStage />
                        </Grid>
                        <Grid item>
                            <DurationItem />
                        </Grid>
                    </Grid>
                </TabPanel>
            </AppBar>
            {props.children}
        </Box>
    );
};

Bar.propTypes = {};

export default withStyles(styles)(Bar);
