import 'firebase/firestore';

import { Avatar, Card, CardContent, CardHeader } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import { DurationContext } from 'context/format/Duration';
import { QueryRequestProvider } from 'context/query/request';
import { QueryResponseProvider } from 'context/query/response';
import { ViewGroupContext } from 'context/view/group';
import { ViewTournamentContext } from 'context/view/tournament';
import ShareDialog from 'dialogs/ShareDialog/Share.js';
import ViewLayoutRoundList from 'layout/view/round/list';
import { GenerateColor } from 'layout/view/utl';
import { TableLarge } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';
import { RoundQuery } from 'request/Tournament/Round';

import { Standings } from './standings';

export default function ViewLayoutGroup(props) {
    const { duration } = useContext(DurationContext);

    const { tournament } = useContext(ViewTournamentContext);
    const { group, groupScore } = useContext(ViewGroupContext);
    const [showShareGroup, setShowShareGroup] = useState(false);
    const color = GenerateColor(group.object.id + 'group-color-seed');

    const outboundOf = {
        groupIds: [{ id: group.object.id }],
    };

    if (group === null) {
        return <div />;
    }

    return (
        <div>
            <Card>
                <CardHeader
                    title={'Group ' + group.object.id.substring(0, 6)}
                    subheader={[
                        <Typography key="total_entries" variant="caption">
                            Total Entries: {group.base.entriesCount}
                        </Typography>,
                        <Typography key="qualifiers" variant="caption">
                            <br />
                            Qualifiers: {group.object.totalQualifiers}
                        </Typography>,
                    ]}
                    // subheader={(typeof this.state.stage !== "undefined") && (<Typography variant="caption">Start Week: {this.state.stage.duration.start} | Final Week: {this.state.stage.duration.end}<br />Qualifiers Per Group: {this.state.stage.format.qualifiers}</Typography>)}
                    avatar={
                        <Avatar
                            aria-label="recipe"
                            style={{ backgroundColor: color }}
                        >
                            <TableLarge />
                        </Avatar>
                    }
                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={() => setShowShareGroup(true)}
                        >
                            <ShareIcon color="primary" />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Standings
                        score={groupScore}
                        color={color}
                        maxQualifiers={group.object.totalQualifiers}
                        totalEntries={group.base.entryList.length}
                        group={group}
                    ></Standings>
                </CardContent>
                <CardContent>
                    <QueryRequestProvider
                        tournamentID={props.tournamentID}
                        outboundOf={outboundOf}
                    >
                        <QueryResponseProvider QueryFunc={RoundQuery}>
                            <ViewLayoutRoundList startWeek={duration.start} />
                        </QueryResponseProvider>
                    </QueryRequestProvider>
                </CardContent>
            </Card>
            <ShareDialog
                open={showShareGroup}
                shareTitle={'Share Group: ' + group.object.id}
                title={
                    'Check out my group in ' +
                    tournament.object.name +
                    ', an FPL tournament created on fplplugin.com'
                }
                url={
                    'https://fplplugin.com/tour/' +
                    tournament.object.id +
                    '/group/' +
                    group.object.id
                }
                closeFunc={() => setShowShareGroup(false)}
            ></ShareDialog>
        </div>
    );
}
