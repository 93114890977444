import { Box } from '@material-ui/core';
import BackdropItem from 'componets/backdrop/item/item';
import CreateDialog from 'componets/create/createDialog';
import DurationView from 'containers/create/Duration';
import { useDuration, useError } from 'context/create/Provider';
import { CalendarStart } from 'mdi-material-ui';
import React, { useState } from 'react';

export const DurationItem = () => {
    const [open, setOpen] = useState(false);
    const error = useError();
    const duration = useDuration();
    const onSave = () => {
        setOpen(false);
    };
    const name = 'Starts GW' + duration.start;
    return (
        <Box>
            <BackdropItem
                icon={<CalendarStart />}
                placeholder={'Select the starting game week'}
                placeholderActive={false}
                value={name}
                disabled={false}
                error={error}
                onClick={() => setOpen(true)}
            ></BackdropItem>
            <CreateDialog open={open} onClose={() => setOpen(false)}>
                <DurationView handleSave={onSave} />
            </CreateDialog>
        </Box>
    );
};

export default DurationItem;
