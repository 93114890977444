import axios from 'axios';

const url = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE + '/tournament';

const urlWithID = (tournamentID) => {
    return url + '/' + tournamentID + '/duration';
};

export const DurationPost = (
    authUser,
    duration,
    setDuration,
    tournament,
    setDurationError
) => {
    authUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const opts = {
            method: 'post',
            url: urlWithID(tournament.id),
            data: duration,
            headers: { Authorization: 'Bearer ' + idToken },
        };
        axios(opts)
            .then(function (response) {
                setDuration(response.data);
                setDurationError(null);
            })
            .catch(function (error) {
                console.log('setDurationError', error.response, duration);
                setDurationError(error.response);
            });
    });
};
