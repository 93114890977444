import 'firebase/firestore';

import { CardActionArea, Typography } from '@material-ui/core';
import ViewLayoutName from 'layout/view/name';
import React from 'react';

export default function GameCardActionOne(props) {
    return (
        <CardActionArea key={props.id} onClick={props.click}>
            <Typography
                variant="body2"
                style={{ padding: '1px', paddingLeft: '3px' }}
            >
                <ViewLayoutName entry_id={props.id}></ViewLayoutName>
            </Typography>
        </CardActionArea>
    );
}
