// React
// Firebase
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/performance';

import { Box, Button } from '@material-ui/core';
// validate.js
import React from 'react';

export const LoginCallToActionButton = ({
    isSignedIn,
    isPerformingAuthAction,
    handleSignInClick,
}) => {
    return (
        <Box>
            {!isSignedIn && (
                <Button
                    style={{ margin: '10%', maxWidth: 350, fontSize: 15 }}
                    color="primary"
                    disabled={isPerformingAuthAction}
                    variant="contained"
                    onClick={() => {
                        handleSignInClick();
                    }}
                >
                    Login to create & view tournaments
                </Button>
            )}
        </Box>
    );
};
export default LoginCallToActionButton;
