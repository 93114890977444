import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import React from 'react';

export default function IconLabelButtons(props) {
    let color = 'default';
    let icon = <CloseIcon />;
    if (props.ready) {
        color = 'primary';
        icon = <DoneIcon />;
    }
    return (
        <Chip
            className={props.className}
            label={props.name}
            icon={icon}
            color={color}
            size="medium"
        />
    );
}
