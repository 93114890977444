import GameList from 'componets/game/list';
import { QueryResponseContext } from 'context/query/response';
import React, { useContext } from 'react';

export default function GameListContext(props) {
    const { queryResponse } = useContext(QueryResponseContext);

    return (
        <GameList
            queryResponse={queryResponse}
            totalQualifiers={props.totalQualifiers}
        ></GameList>
    );
}
