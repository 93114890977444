/* eslint-disable prettier/prettier */
import {Paper,Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React from 'react';

const MyPaper = styled(Paper)({
    borderRadius: 5,
    backgroundColor: '#5d1049',
    color: '#fff',
    padding: 5,
});

export default function GameHeading({start,position,totalGames}) {
    return (
        <MyPaper>
        <Typography variant="caption" align="left">
            Game{' '}
            {position ? position + start : 1}{' '}
            of {totalGames}
        </Typography>
        </MyPaper>
    );
}
