import { ViewTournamentContext } from 'context/view/tournament';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { SeriesGetNoAuth } from 'request/Tournament/Series';

export const ViewSeriesContext = createContext(null);

export const ViewSeriesProvider = (props) => {
    const { children } = props;
    const { tournament } = useContext(ViewTournamentContext);
    const [series, setSeries] = useState({
        object: {
            id: props.id,
        },
        base: {
            entryList: [],
            scoreList: [],
            status: {
                value: '',
            },
        },
    });

    const setError = (error) => {
        console.log(error);
    };

    console.log('series context', series);
    useEffect(() => {
        if (series.object.id !== '') {
            SeriesGetNoAuth(
                tournament.object.id,
                series.object.id,
                setSeries,
                setError
            );
        }
    }, [series.object.id]);

    return (
        <ViewSeriesContext.Provider
            value={{
                series,
                setSeries,
            }}
        >
            {children}
        </ViewSeriesContext.Provider>
    );
};
